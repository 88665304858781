import React from "react"
import {withRouter} from "react-router-dom"
import {stars} from "../../util/rating_util"
import UserAvatar from "../photographers/photograher_avatar"
import {serviceIcon} from "../../util/service_util";
import {formatDatetime} from "../../util/date_util";
import PhotographerReviews from "../photographers/photographers_reviews"
import { fetchPhotographerWithReviews } from "../../util/service_api_util"
import Modal from "react-modal";
import LogoSpinner from "../../../../assets/images/landing/video-thumbnails/MA-logo-spinner-transparent.gif";

class ContractorItem extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      reviewsModalOpen: false,
      detailedPhotographer: null,
      reviewsLoading: false,
}

    this.changeSelected = this.changeSelected.bind(this)
    this.changeSelectedModal = this.changeSelectedModal.bind(this)
    this.toggleReviewsModal = this.toggleReviewsModal.bind(this);
    this.fetchDetailedPhotographer = this.fetchDetailedPhotographer.bind(this);
  }

  toggleReviewsModal(e) {
    if (!this.state.reviewsModalOpen) {
      // Open modal and fetch detailed photographer
      this.fetchDetailedPhotographer();
    } else {
      // Close modal
      this.setState({ reviewsModalOpen: false });
    }
  }

  async fetchDetailedPhotographer() {
    this.setState({ reviewsLoading: true, reviewsModalOpen: true });
    try {
      const detailedPhotographer = await fetchPhotographerWithReviews(this.props.contractor.id);
      this.setState({ detailedPhotographer, reviewsLoading: false });
    } catch (error) {
      console.error("Failed to fetch detailed photographer:", error);
      this.setState({ reviewsLoading: false });
    }
  }

  changeSelected() {
    if (!this.state.reviewsModalOpen){
      let contractor_id = this.props.contractor.id

      if (this.props.selected) {
        contractor_id = -1
      }
      this.props.onSelect(contractor_id)
    }
  }

  changeSelectedModal(){
    let contractor_id = this.props.contractor.id

    if (this.props.selected) {
      contractor_id = -1
    }
    this.props.onSelect(contractor_id)
    this.toggleReviewsModal()
  }

  render() {

    const { reviewsLoading, detailedPhotographer, reviewsModalOpen } = this.state;

    let photographer = this.props.contractor

    let star_rating = stars(photographer.average_rating)

    let id = ""
    let text = "Availability"
    let appointmentTime = ""

    if (this.props.services && this.props.services[this.props.selectedService] && this.props.services[this.props.selectedService]["contractor_id"] === photographer.id) {
      id = "contractorSelectedForTimeSlot"
      text = "Change time"
      let upcoming_appointment = this.props.appointments[this.props.selectedService].appointment_time
      appointmentTime = <div>{formatDatetime(upcoming_appointment, null, this.props.detected_timezone || this.props.client_timezone || this.props.project?.client?.timezone)}</div>
    }

    if (this.props.reschedulingContractorCancel && this.props.selected_contractor?.id && this.props.selected_contractor?.id === photographer.id) {
      id = "contractorSelectedForTimeSlot"
      text = "Change time"
      let upcoming_appointment = this.props.appointment_time
      appointmentTime = <div>{formatDatetime(upcoming_appointment, null, this.props.detected_timezone || this.props.client_timezone || this.props.project?.client?.timezone)}</div>
    }

    if (this.props.contractorDateSearch) {
      text = "Select"
    }

    if (this.props.contractorDateSearch && this.props.services && this.props.services[this.props.selectedService] && this.props.services[this.props.selectedService]["contractor_id"] === photographer.id || (this.props.reschedulingContractorCancel && this.props.selected_contractor?.id && this.props.selected_contractor?.id === photographer.id)){
      text = "Selected"
    }
  
    return (
      <div className="photographerCard contractorCard" id={id} onClick={this.changeSelected}>
        <Modal
          isOpen={this.state.reviewsModalOpen}
          onRequestClose={(e) => {
            e.stopPropagation(); // Prevent click propagation to background elements
            this.toggleReviewsModal(); // Call the toggle function
          }}
          ariaHideApp={false}
          className="photographerReviewModal"
          overlayClassName="reviewModalOverlay"
        >
          {reviewsLoading ? (
            <div>
              <div className="createProjectLogoSpinner">
                <img className="logoImageSpinnerGif" src={LogoSpinner}></img>
              </div>
            </div>
          ) : detailedPhotographer ? (
              <div onClick={(e) => e.stopPropagation()}>
                <PhotographerReviews reviewModal={true} changeSelected={this.changeSelectedModal} toggleReviewsModal={this.toggleReviewsModal}  currentUser={this.props.currentUser} photographer={detailedPhotographer} />
              </div>
          ) : (
            <p>No reviews available.</p>
          )}
        </Modal>
        <div className="photographerCardHeader" id="contractorCardHeader">
          <div className="photographerCardGrouping">
            <UserAvatar user={photographer}/>
            <div className="photographerCardInfo">
              <div className="photographerCardName">{photographer.firstName} {photographer?.lastName[0]}</div>
              {photographer.ratings_amount > 0 && <div className="photographerCardRatingNew">
                <div
                  className="photographerCardReviewNew"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent event propagation
                    this.toggleReviewsModal(); // Call the toggleReviewsModal method
                  }}
                >
                  {photographer.ratings_amount} Reviews
                </div>
                {/* <div>{star_rating}</div> */}
              </div>}
              <div className="photographerMainInfo">
                <a className="photographerCardButton" href={`/photographers/${this.props.contractor.id}`}
                   target="_blank"
                  onClick={(e) => e.stopPropagation()} >
                  <i className="far fa-user-circle"/>
                  Profile
                </a>
                <div className="photographerCardButton" onClick={this.changeSelected}>
                  <i className="far fa-calendar"/>
                  {text}
                </div>
                <div className="photographerAppointmentLabel">
                  {appointmentTime}
                </div>
              </div>
            </div>
            {photographer.ratings_amount > 0 && <div className="photographerCardRatingOld">
              {/* <div>{star_rating}</div> */}
              <div
                className="photographerCardReview"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent event propagation
                  this.toggleReviewsModal(); // Call the toggleReviewsModal method
                }}
              >
                {photographer.ratings_amount} Reviews
              </div>
            </div>}
          </div>
          <div className="photographerMainInfoMobile">
            <a className="photographerCardButton" href={`/photographers/${this.props.contractor.id}`}
              target="_blank">
              <i className="far fa-user-circle" />
              Profile
            </a>
            <div className="photographerCardButton" onClick={this.changeSelected}>
              <i className="far fa-calendar" />
              {text}
            </div>
            <div className="photographerAppointmentLabel">
              {appointmentTime}
            </div>
          </div>
        </div>
        <div className="photographerCardBody">
          <div className="photographerCardServices">
            {photographer?.services?.map(service => {
              if (!service.reshoot){
              const ServiceIcon = serviceIcon(service.slug)
              return (
                <div className="photographerCardService" key={`service-${service.id}`}>
                  <ServiceIcon className="photographerCardServiceIcon" />
                  <span className="photographerCardServiceName">{service.title}</span>
                </div>
              )
              }
            })}
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(ContractorItem)