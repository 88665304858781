export const fetchAllUsers = (role = null, search = null, approved = null, firm = null, page = 0, pageSize = 10) => (
  $.ajax({
    type: "GET",
    url: `/users`,
    data: {role, search, approved, firm, page, pageSize}
  })
);

export const fetchUsersNameAndIdOnly = (search = null) => (
  $.ajax({
    type: "GET",
    url: "/users_name_id_only",
    data: {search: search}
  })
);


export const fetchPhotographersMap = () => (
  $.ajax({
    type: "GET",
    url: "/fetch_photographers_map",
  })
);

export const fetchCalendarEvents = (user_id, date) => (
  $.ajax({
    type: "GET",
    url: "/fetch_calendar_events",
    data: {user_id: user_id, date: date}
  })
)

export const fetchUser = (id) => (
  $.ajax({
    type: "GET",
    url: `/fetch_user`,
    data: {user_id: id}
  })
);

export const deleteUser = (user_id) => (
  $.ajax({
    type: "DELETE",
    url: `/soft_destroy`,
    data: {id: user_id}
  })
);

export const destroyUser = (user_id, reason = null) => (
  $.ajax({
    type: "DELETE",
    url: `/hard_destroy`,
    data: { id: user_id, reason: reason }
  })
);

export const postUserToPaymentService = (user_id) => (
  $.ajax({
    type: "PUT",
    url: `/users/${user_id}/post_to_payment_service`,
  })
);

export const confirmUser = (user_id) => (
  $.ajax({
    type: "PUT",
    url: `/users/${user_id}/confirm_user`,
  })
)

export const approveUser = (user_id) => (
  $.ajax({
    type: "PUT",
    url: `/users/${user_id}/approve`,
  })
)

export const rejectUser = (user_id) => (
  $.ajax({
    type: "PUT",
    url: `/users/${user_id}/reject`,
  })
)
