import React from "react";
import {withRouter} from "react-router-dom";
import SettingsProfileContainer from "./profile/settings_profile_container";
import SettingsCompanyContainer from "./company/settings_company_container";
import SettingsPreferencesContainer from "./preferences/settings_preferences_container";
import SettingsServicesContainer from "./services/settings_service_container";
import SettingsTimeSlotsContainer from "./times/settings_time_slots_container";
import SettingsPaymentContainer from "./payments/settings_payment_container";
import SettingsUsersContainer from "./users/settings_users_container";
import VirtualStagingEditContainer from "./virtual-staging/virtual_staging_edit_container";
import SettingsFirmsContainer from "./firms/settings_firms_container";
import SettingsGalleryContainer from "./gallery/settings_gallery_container";
import SettingsPayoutsContainer from "./payouts/settings_payouts_container";
import SettingsTermsOfServiceContainer from "./terms-of-service/settings_terms_of_service_container";
import SettingsSubBrokersContainer from "./sub-brokers/settings_sub_brokers_container";
import SettingsFaqsContainer from "./faq/settings_faqs_container";
import SettingsPromosContainer from "./promo/settings_promos_container";
import SettingsTimesContainer from "./times/settings_times_container";
import SettingsZipcodesContainer from "./zip-codes/settings_zipcodes_container"
import SettingsAdsContainer from "./ads/settings_ads_container";
import SettingsBlogCategoriesContainer from "./blogs/blog_categories_container";
import SettingsBundlesContainer from "./bundles/bundles_container";
import SettingsDiscountCodesContainer from "./discount_codes/discount_codes_container";
import SettingsLocationsContainer from "./locations/settings_locations_container";
import SettingsMilestonesContainer from "./milestones/settings_milestones_container";
import SettingsContractorsContainer from "./contractors/settings_contractors_container";
import SettingsNotificationsContainer from "./notifications/settings_notifications_container";
import SettingsLocationPricesContainer from "./location_prices/settings_location_prices_container";
import SettingsSalesDataContainer from "./revenue/settings_revenue_container";


class SettingsMain extends React.Component {
  constructor(props) {
    super(props);

    const userTabs = Array.from(new Set(
      Object.keys(this.props.currentUser.super_admin ? {super_admin: true} : this.props.currentUser.roles).flatMap(role => this.roleTabs(role))
    ))

    const urlTab  = this.props.match.params.tab
    this.state = {
      activeTab: urlTab && userTabs.includes(urlTab) ? urlTab : 'profile'
    }

    this.updateActiveTab = this.updateActiveTab.bind(this)
  }

  updateActiveTab(activeTab) {
    this.setState({activeTab})

    this.props.history.push({
      pathname: `/settings/${activeTab}`,
    })
  }

  tab(tab) {
    const tabs = {
      profile: {component: <SettingsProfileContainer logout={this.props.logout} />, label: 'Profile'},
      services: {component: <SettingsServicesContainer/>, label: 'Services'},
      users: {component: <SettingsUsersContainer />, label: 'Users'},
      'virtual-staging': {
        component: <VirtualStagingEditContainer/>,
        label: this.props.isLargeScreen ? 'Virtual Staging' : "VS"
      },
      firms: {component: <SettingsFirmsContainer/>, label: 'Firms'},
      'terms-of-service': {
        component: <SettingsTermsOfServiceContainer/>,
        label: this.props.isLargeScreen ? 'ToS' : 'ToS'
      },
      gallery: {component: <SettingsGalleryContainer/>, label: 'Gallery'},
      'time-slots': {component: <SettingsTimeSlotsContainer/>, label: 'Timeslots'},
      payouts: {component: <SettingsPayoutsContainer/>, label: 'Payment Info'},
      preferences: {component: <SettingsPreferencesContainer/>, label: 'Preferences'},
      company: {component: <SettingsCompanyContainer/>, label: 'Company'},
      payment: {component: <SettingsPaymentContainer/>, label: 'Payment Method'},
      'sub-brokers': {component: <SettingsSubBrokersContainer />, label: 'Sub-brokers'},
      faq: {component: <SettingsFaqsContainer />, label: 'FAQ'},
      ads: {component: <SettingsAdsContainer />, label: 'Ads'},
      promos: {component: <SettingsPromosContainer />, label: 'Promos'},
      times: { component: <SettingsTimesContainer isLargeScreen={this.props.isLargeScreen}/>, label: 'Times'},
      zipcodes: {component: <SettingsZipcodesContainer />, label: 'Service Area'},
      blogs: {component: <SettingsBlogCategoriesContainer />, label: 'Blogs'},
      bundles: {component: <SettingsBundlesContainer />, label: "Bundles"},
      discount_codes: {component: <SettingsDiscountCodesContainer />, label: "Coupons"},
      locations: {component: <SettingsLocationsContainer />, label: "Locations"},
      milestones: {component: <SettingsMilestonesContainer />, label: "Milestones" },
      contractors: {component: <SettingsContractorsContainer />, label: "Contractors"},
      notifications: {component: <SettingsNotificationsContainer />, label: "Notifications"},
      location_pricing: {component: <SettingsLocationPricesContainer />, label: "Location Pricing"},
      revenue: {component: <SettingsSalesDataContainer />, label: "Revenue"}
    }

    return tabs[tab]
  }

  roleTabs(role) {
    const tabs = {
      super_admin: ['profile', 'services', 'users', 'virtual-staging', 'firms', 'terms-of-service', 'faq', 'ads', 'promos', 'times', 'blogs', 'bundles', 'discount_codes', 'locations', 'milestones', 'contractors', 'notifications', "location_pricing", "revenue"],
      admin: ['profile', 'services', 'users', 'virtual-staging', 'firms', 'terms-of-service', 'faq', 'ads', 'promos', 'times', 'blogs', 'bundles', 'discount_codes', 'locations', 'milestones', 'contractors', 'notifications', "location_pricing"],
      contractor: ['profile', 'gallery', 'time-slots', 'zipcodes', 'payouts'],
      broker: ['profile', 'company', 'payment', 'sub-brokers'],
      post_production: ['profile'],
      sales_rep: ['profile', 'payouts'],
      default: ['profile', 'company', 'payment']
    }

    return tabs[role] || tabs.default
  }

  render() {
    const {isLargeScreen, currentUser} = this.props

    // if (currentUser.roles.post_production) {
    //   location.replace("/")
    //   return <div/>
    // }

    const projectsNavClass = `projectsIndexProjectsNav${isLargeScreen ? '' : 'Mobile'}`
    const projectsNavItemClass = `projectsIndexProjectsNavItem${isLargeScreen ? '' : 'Mobile'}`
    const activeTabId = `projectsIndexProjectsNavActive${this.props.isLargeScreen ? '' : 'Mobile'}`

    const userTabs = Array.from(new Set(
      Object.keys(this.props.currentUser.roles).flatMap(role => this.roleTabs(this.props.currentUser.super_admin ? 'super_admin' : role))
    ))
    return (
      <div>
        <div className={projectsNavClass} id="settingsNavClass">
          {userTabs.map(tab =>
            <div onClick={() => this.updateActiveTab(tab)} className={projectsNavItemClass}
                 key={tab} id={this.state.activeTab === tab ? activeTabId : ''}>
              {this.tab(tab)?.label}
            </div>
          )}
        </div>
        <div className="settingsMainComponent">
          {this.tab(this.state.activeTab).component}
        </div>
      </div>
    )
  }
}

export default withRouter(SettingsMain)