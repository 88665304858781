import React from "react"
import {withRouter} from "react-router-dom"
import reviewIcon from "../../../../assets/images/reviewIcon"
import {ReactComponent as RightIcon} from "../../../../assets/images/icons/chevron-right.svg"
import {ReactComponent as LeftIcon} from "../../../../assets/images/icons/chevron-left.svg"
import {stars} from "../../util/rating_util"
import { removeReview } from "../../util/project_api_util"
import {formatDatetime} from "../../util/date_util";

class PhotographerReviews extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      page: 0,
      perPage: 4
    }
  }

  getInitials(name) {
    return name.split(' ').splice(0, 2).map(word => word[0].toUpperCase()).join(' ')
  }

  removeRating(id){
    removeReview(id).then(() => {
      window.location.reload();
    })
  }

  navigateToPrevPage = (e) => {
    e.preventDefault()
    this.setState({page: Math.max(0, this.state.page - 1)})
  }

  navigateToNextPage = (e) => {
    e.preventDefault()
    const ratings = this.props.photographer.ratings || []
    this.setState({page: Math.min(this.state.page + 1, ratings.length - 1)})
  }

  render() {
    let photographer = this.props.photographer
    let photographerRatings = photographer.ratings || []
    let reviews = this.props.reviewModal ? photographerRatings : photographerRatings.slice(this.state.page * this.state.perPage, (this.state.page + 1) * this.state.perPage)

    const hasPrev = this.state.page > 0
    const hasNext = photographerRatings.length > (this.state.page + 1) * this.state.perPage

    return (
      <div className="photographerReviews">
        <div className="photographerReviewsHeader">
          <div className="photographerReviewsHeading">
            <div className="photographerReviewsTitle" id="photographerReviewsTitleModal">
              {this.props.reviewModal ? `${photographer.firstName}'s Reviews` : "Photographer Reviews"}
            </div>
            {this.props.reviewModal && (
              <div className="closeReviewsIcon" onClick={this.props.toggleReviewsModal}>
                &times;
              </div>
            )}
          </div>

          {reviews.length > 0 && !this.props.reviewModal && (
            <div className="photographerReviewNavigation">
              <a className={hasPrev ? "" : "disabled"} onClick={(e) => this.navigateToPrevPage(e)}>
                <LeftIcon />
              </a>
              <a className={hasNext ? "" : "disabled"} onClick={(e) => this.navigateToNextPage(e)}>
                <RightIcon />
              </a>
            </div>
          )}
        </div>

        {reviews.length === 0 && photographer && (
          <div className="emptyPlaceholder">
            <img src={reviewIcon} />
            <div className="emptyPlaceholderText">No reviews yet for this photographer</div>
          </div>
        )}

        {reviews.length > 0 && (
          <div className="photographerReviewsContainer" id={this.props.reviewModal ? "photographerReviewsModalContainer" : ""}>
            {reviews.map((review, i) => {
              const star_rating = stars(review.value);
              let review_date = new Date(review.created_at);

              return (
                <div
                  className="photographerReview"
                  key={`review-${i}-${this.state.page}`}
                  id={this.props.reviewModal ? "reviewModalReview" : ""}
                >
                  <div className="d-flex">
                    <div className={`photographerReviewInitials photographerReviewInitialsColor-${i + 1}`}>
                      {this.getInitials(review.name)}
                    </div>
                    <div className="photographerReviewNameAndDate">
                      <div className="photographerReviewName">{review.name}</div>
                      <div className="photographerReviewDate">{formatDatetime(review_date, "DD MMMM YYYY")}</div>
                    </div>
                  </div>
                  <div className="photographerReviewRating">{star_rating}</div>
                  <div className="photographerReviewContent">{review.comment}</div>
                  {this.props.currentUser?.roles?.admin && (
                    <div
                      onClick={() => {
                        if (window.confirm("Are you sure you want to delete this review?")) {
                          this.removeRating(review.id);
                        }
                      }}
                      className="reviewDestroy"
                    >
                      Delete
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}

        {this.props.reviewModal && (
          <div className="photographerReviewsFooter">
            <button className="bookPhotographerButton" onClick={this.props.changeSelected}>
              Book This Photographer
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(PhotographerReviews)