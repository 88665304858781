import React from "react";
import { withRouter } from "react-router-dom";
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { fetchAllUsers} from "../../../util/users_api_util";
import GalleryModal from "../../gallery/gallery_modal";
import UploadGalleryPhotosModal from "./upload_gallery_photos_modal";
import LogoSpinner from "../../../../../assets/images/landing/video-thumbnails/MA-logo-spinner.gif";
import { deleteGalleryMedia } from "../../../util/settings_api_util"


const SortableItem = SortableElement(({ value }) => {
  return <div className="editProjectPhoto">
    <img className="galleryPhotoPropertyTourSortable" src={value.url}></img>
  </div>
})

const SortableList = SortableContainer(({ items }) => {
  return (
    <div className="editProjectPhotos" >
      {items.map((value, index) => (
        <SortableItem key={`item-${value.id}`} index={index} value={value} />
      ))}
    </div>
  )
})

class SortableComponent extends React.Component {
  render() {
    return <SortableList items={this.props.data} onSortEnd={this.props.onSortEnd} axis="xy" />;
  }
}


class SettingsGallery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stored_images: [],
      photographer: {},
      sorting: false,
      galleryModal: false,
      currentPhoto: 0,
      modalOpen: false,
      contractors: [],
      selectedMedia: [],
      photoSelection: false,
      user_id: this.props.currentUser.roles.admin ? this.props.user_id : this.props.currentUser.id,
      loading: false
    }

    this.handleInput = this.handleInput.bind(this)
    this.handler = this.handler.bind(this)
    this.createGallery = this.createGallery.bind(this)
    this.updateGallery = this.updateGallery.bind(this)
    this.onDeleteMedia = this.onDeleteMedia.bind(this)
    this.reorderPhotos = this.reorderPhotos.bind(this)
    this.changeContractor = this.changeContractor.bind(this)
    this.disableSelection = this.disableSelection.bind(this)
    this.openUploadModal = this.openUploadModal.bind(this)
    this.selectedMedia = this.selectMedia.bind(this)
    this.cancelSort = this.cancelSort.bind(this)
    this.submitSort = this.submitSort.bind(this)
    this.selectAllMedia = this.selectAllMedia.bind(this)
    this.reloadGalleryMedias = this.reloadGalleryMedias.bind(this)
  }

  componentDidMount(){
    if (this.state.user_id){
      this.setState({loading: true})
      this.props.fetchPhotographer(this.state.user_id).then(photographer => this.setState({ stored_images: photographer.images || [], photographer: photographer, loading: false }))
    } else {
      fetchAllUsers("contractor", null, null, null, null, 100000).then(contractors => {
        this.setState({ contractors: contractors })
      })
    }
  }

  selectAllMedia(medias) {
    let selected_medias = this.state.selectedMedia

    medias.forEach(media => selected_medias[media.id] = media)

    this.setState({ selectedMedia: selected_medias })
  }

  reloadGalleryMedias(){
    this.setState({ loading: true })
    this.props.fetchPhotographer(this.state.user_id).then(photographer => this.setState({ stored_images: photographer.images || [], photographer: photographer, loading: false }))
  }

  onDeleteMedia() {
    if (Object.values(this.state.selectedMedia).length === 0) {
      this.props.showToast("No photos selected")
    } else {
      if (!window.confirm('Are you sure you want to delete these photos?')) return
      const media_ids = Object.values(this.state.selectedMedia).map(media => media.id)
      deleteGalleryMedia(media_ids, this.state.photographer?.gallery?.id).then((result) => {
        this.reloadGalleryMedias()
        this.setState({ selectedMedia: {}, photoSelection: false })

        if (result.notice) {
          this.props.showToast(result.notice)
        }
      })
    }
  }

  changeContractor(e){
    e.preventDefault()
    if (e.target.value === "") {
      this.setState({ user_id: null, stored_images: [], photographer: {} })
    } else {
      this.setState({loading: true})
      this.props.fetchPhotographer(e.target.value).then(photographer => this.setState({ stored_images: photographer.images || [], photographer: photographer, loading: false }))
      this.setState({ user_id: e.target.value})
    }
  }

  handler(newState) {
    this.setState(newState)
  }

  handleInput(field) {
    return e => this.setState({ [field]: e.currentTarget.value });
  }

  reorderPhotos({ oldIndex, newIndex }) {
    const photos = this.state.stored_images
    const [reorderedItem] = photos.splice(oldIndex, 1);
    photos.splice(newIndex, 0, reorderedItem);
    this.setState({ stored_images: photos })
  }

  cancelSort(){
    this.setState({sorting: false})
    this.reloadGalleryMedias()
  }

  submitSort(){
    let sorted = this.state.stored_images

    let all_sorted_medias = {}

    sorted.forEach((media, index) => {
      all_sorted_medias[media.id] = index
    })

    this.props.sortMedia(all_sorted_medias).then(() => {
      this.props.showToast("Gallery has been updated")
      this.setState({sorting: false})
    })
  }

  disableSelection(e) {
    e.preventDefault()
    this.setState({ photoSelection: false, selectedMedia: {} })
  }

  createGallery() {
    let formData = new FormData();


    if (this.state.cover) {
      formData.append("gallery[cover]", this.state.cover);
    }

    formData.append("gallery[galleriable_id]", this.state.photographer.id);
    formData.append("gallery[galleriable_type]", "User");

    for (let i = 0; i < this.state.images.length; i++) {
      formData.append(`gallery[images][]`, this.state.images[i])
    }

    this.props.createGallery(formData).then(() => {
      this.props.showToast("Gallery has been created")
    })
  }

  selectMedia(media, i) {
    if (this.state.photoSelection) {
      let medias = Object.assign({}, this.state.selectedMedia)

      if (medias[media.id]) {
        delete medias[media.id]
      } else {
        medias[media.id] = media
      }

      this.setState({ selectedMedia: medias })
    } else {
      this.setState({ galleryModal: true, currentPhoto: i })
    }
  }

  openUploadModal(e) {
    e.preventDefault()
    if (this.state.upload_loading) {
      this.props.showToast("Upload in progress")
    } else {
      this.setState({ modalOpen: true })
    }
  }

  updateGallery() {
    let formData = new FormData();
    formData.append("gallery[title]", "Best Photos");
    if (this.state.cover){
    formData.append("gallery[cover]", this.state.cover);
    }
  
    for (let i = 0; i < this.state.images.length; i++) {
      formData.append("gallery[images][]", this.state.images[i])
    }
    for (let i = 0; i < this.state.deleted_images.length; i++) {
      formData.append("deleted_images[]", this.state.deleted_images[i].id)
    }

    this.props.updateGallery(formData, this.state.photographer.gallery.id).then(() => {
      let sorted = this.state.stored_images

      let all_sorted_medias = {}

      sorted.forEach((media, index) => {
        all_sorted_medias[media.id] = index
      })

      this.props.sortMedia(all_sorted_medias).then(() => {
        this.props.showToast("Gallery has been updated")
      })
    })
  }

  render() {

    let sortPhotosButton = ""
    let photoGallery = ""
    let selectionButtons = ""
    let imagesArray = this.state.stored_images
    let buttons = ""
    let modal = ""
    let selectedId = "projectEditPhotoSelected"

    if (this.state.modalOpen) {
      modal = <UploadGalleryPhotosModal changeParentState={this.handler}
        photographer={this.state.photographer}
        reloadGalleryMedias={this.reloadGalleryMedias}
        isLargeScreen={this.props.isLargeScreen}
        updateGallery={this.props.updateGallery}
        createGallery={this.props.createGallery} 
        showToast={this.props.showToast}/>
    }

    if (imagesArray.length > 0){
      buttons = <div className="text-center">
        <button onClick={this.createGallery} className="settingsButton subServicesButton">Create Gallery</button>
      </div>
    }

    if (this.state.photographer.images && this.state.photographer.images.length > 0) {
      buttons =
        <div>
          <button onClick={() => this.props.deleteGallery(this.props.service.gallery.id)} className="settingsButton subServicesButton deleteButton">Delete Gallery</button>
          <button onClick={this.updateGallery} className="settingsButton subServicesButton updateGalleryButton">Update Gallery</button>
        </div>
    }

    if (this.state.galleryModal) {
      modal = <GalleryModal photos={imagesArray} changeParentState={this.handler} currentPhoto={this.state.currentPhoto} />
    }

    if (this.state.user_id){

      const selectedText = this.props.isLargeScreen ? "selected" : ""

      let images = <>
        {imagesArray.map((media, i) => {
            return (<div onClick={() => this.selectMedia(media, i)} className="editProjectPhoto" key={media.id}
              id={this.state.selectedMedia[media.id] ? selectedId : ""}>
              <img src={media.url} />
            </div>)
        })}
      </>
      if (this.state.loading){
        images = <div className="loaderDiv">
          <img className="logoImageSpinnerGif" src={LogoSpinner}></img>
        </div>
      }

      if (this.state.sorting){
        images = <SortableComponent data={this.state.stored_images} onSortEnd={this.reorderPhotos} />
      }

      if (!this.state.photoSelection && !this.state.sorting) {
        selectionButtons = <div className="projectEditPhotosButtons">
          <div onClick={() => this.setState({ photoSelection: true })} className="submitPhotosButton">
            Select
          </div>
          <div onClick={() => this.setState({ sorting: true })} className="submitPhotosButton">
            Sort
          </div>
        </div>
      } else if (this.state.sorting){
        selectionButtons = <div className="projectEditPhotosButtons">
          <div onClick={this.submitSort} className="submitPhotosButton">
            Save
          </div>
          <div onClick={this.cancelSort} className="submitPhotosButton">
            Cancel
          </div>
        </div>
      } else {
        selectionButtons = <div className="projectEditPhotosButtons">
          <div className="numberSelectedPhotos">{Object.values(this.state.selectedMedia).length} {selectedText}</div>

          <div className="submitPhotosButton deletePhotoButton" onClick={() => this.selectAllMedia(imagesArray)}>
            {this.props.isLargeScreen ? "Select All" : "All"}
          </div>

          <div className="submitPhotosButton deletePhotoButton clearSelectionButton" onClick={() => this.setState({ selectedMedia: {} })}>
            Clear
          </div>

          <div className="submitPhotosButton deletePhotoButton" onClick={this.onDeleteMedia}>
            Delete
          </div>

          <div onClick={this.disableSelection} className="submitPhotosButton">
            Cancel
          </div>
        </div>
      }

      return (
        <div className="settingsComponentGroupingDiv">
          {modal}
          <div className={this.props.currentUser.roles.admin ? "settingsCompanyAdmin" : "settingsCompany"}>
            <div className="settingsComponentHeading">Gallery</div>
            <div>
              <div className="editProjectPhotosSectionInner">
                <div className="d-flex justify-content-end flex-wrap buttonProjectEditGroup">
                  <div className="d-flex align-items-center buttonProjectEditGroup">
                    <button className="projectEditButton projectEditMarginBottomButton projectEditButtonPrimary projectEditButtonUpload" onClick={this.openUploadModal}>
                      Upload
                    </button>
                  </div>
                </div>

                    <div className="divider" />

                    {selectionButtons}

                    <div className="editProjectPhotos">
                      {images}
                    </div>
                  </div>
                </div>
              </div>
          </div>
      );
    } else {
      return (
        <div className="settingsComponentGroupingDiv">
          {modal}
          <div className="settingsCompany">
            {this.props.currentUser.roles.admin ? <select className="formInput contractorSelect" value={this.state.user_id} onChange={this.changeContractor}>
              <option value="">Contractors</option>
              {
                Object.values(this.state.contractors).map(contractor => {
                  return <option value={contractor.id}>{`${contractor.firstName} ${contractor.lastName}`}</option>
                })
              }
            </select> : ""}
          </div>
        </div>
      );
    }
  }
}

export default withRouter(SettingsGallery);