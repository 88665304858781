import React from "react";
import { fetchCalendarEvents, fetchUser } from "../../../util/users_api_util";
import LogoSpinner from "../../../../../assets/images/landing/video-thumbnails/MA-logo-spinner.gif";
import { railsToJsTimezoneMap } from "../../../util/timezone_utils";


export default class SettingsCalendarEvents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: this.props.currentUser.roles.admin ? this.props.user_id : this.props.currentUser.id,
      user: null,
      loading: false,
      date: null,
      events: []
    }
    this.searchDate = this.searchDate.bind(this)
  }

  componentDidMount() {
    if (this.state.user_id) {
      this.setState({ loading: true })
      fetchUser(this.state.user_id).then(user =>
        this.setState({ user: user, loading: false }))
    }
  }
  
  searchDate(e){
    const selectedDate = e.target.value;
    const userTimeZone = railsToJsTimezoneMap[this.state.user?.timezone] || Intl.DateTimeFormat().resolvedOptions().timeZone;

    const dateInUserTimeZone = new Date(`${selectedDate}T00:00:00`);
    const formattedDate = dateInUserTimeZone.toLocaleDateString("en-CA", { timeZone: userTimeZone });

    this.setState({ loading: true, date: formattedDate });

    fetchCalendarEvents(this.state.user_id, formattedDate).then(events => {
      this.setState({ events: events, loading: false });
    });
  }

  formatDateString(dateString){
    const [year, month, day] = dateString.split("-").map(Number);

    // Array of month names
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    return `${monthNames[month - 1]} ${day}, ${year}`;
  }

  render() {

    const user = this.state.user

    let formattedDate = ""
    let calendar_events = []

    if (user && this.state.date){
     calendar_events = this.state.events.map(event => {
       const startTime = event.start_time;
       const endTime = event.end_time;

       const parsedStartTime = startTime ? new Date(startTime) : null;
       const parsedEndTime = endTime ? new Date(endTime) : null;

       const formattedStartTime = parsedStartTime && !isNaN(parsedStartTime.getTime())
         ? parsedStartTime.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit", timeZone: railsToJsTimezoneMap[this.state.user.timezone] || "UTC" })
         : "Unknown Time";

       const formattedEndTime = parsedEndTime && !isNaN(parsedEndTime.getTime())
         ? parsedEndTime.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit", timeZone: railsToJsTimezoneMap[this.state.user.timezone] || "UTC" })
         : "Unknown Time";

       return (
         <div key={event.summary} className="calendar-event">
           <h3 className="event-title">{event.summary || "No Title"}</h3>
           <p className="event-time">{formattedStartTime} - {formattedEndTime}</p>
         </div>
       );
      })

      formattedDate = this.formatDateString(this.state.date)
    }

    if (this.state.loading || !user) {
      return (<div className="loaderDiv">
        <img className="logoImageSpinnerGif" src={LogoSpinner}></img>
      </div>)
    } else {
      return (
        <div>
          <div className="col-6 col-md-4">
            <input className="formInput" type="date" onKeyDown={(e) => e.preventDefault()}
              onChange={this.searchDate}
              value={this.state.date} />
            <i className="fas fa-calendar calendarIcon" />
          </div>
          <div className="settingsPreferencesGroupingDiv">
            {this.state.date && !this.state.loading && <div className="settingsPreferencesAdmin">
              <div className="settingsComponentHeading" id="calenderEventHeading">Calendar Events for {formattedDate}</div>
              <div className="settingsPreferencesGroup1">
                {calendar_events.length > 0 ? calendar_events : <p>No events scheduled for this day.</p>}
              </div>
            </div>}
            {this.state.loading && <div className="loaderDiv">
              <img className="logoImageSpinnerGif" src={LogoSpinner}></img>
            </div>}
          </div>
        </div>
      );
    }
  }
}