import React, {useState} from 'react'
import googleCalendarImg from "../../../../../assets/images/photographerOnboarding/googleCalendar.svg"
import googleImg from "../../../../../assets/images/photographerOnboarding/google.png"
import {Footer} from "../footer";

export const GoogleCalendar = (props) => {
  const connected = props.data || false

  const onConnect = (e) => {
    e.preventDefault()
    location.href = "/settings/calendars/authorize_access?redirect_to=" + location.href
  }

  const onSubmit = (e) => {
    e.preventDefault()
    props.onNext(connected)
  }

  return <form onSubmit={onSubmit}>
    <div className="photographer-onboarding-form-title">
      Connect your Google <span className="blue">calendar</span>
      <div className="calendarNote">Please make sure the email used for calandar connection is the same as the one used for your Modern Angles account</div>
    </div>

    <div className="photographer-onboarding-center-image-wrapper">
      <img src={googleCalendarImg} />
    </div>

    <button className="projectEditButton projectEditButtonLight photographer-onboarding-connect-button"
            type="button" disabled={connected} onClick={onConnect}>
      <img src={googleImg} />
      <span>Connect with Google</span>
    </button>

    {connected && <div className="photographer-onboarding-paragraph-text alert alert-success text-center">
      Your calendar is connected.
    </div>}

    <Footer onBack={props.onBack} nextDisabled={!connected} onSkip={() => props.onNext(connected)}/>
  </form>
}