import React from "react"
import Map from "../map"
import UserAvatar from "../photographers/photograher_avatar"
import plusIconSrc from "../../../../assets/images/icons/plus-icon.svg"
import buttonTrashIconSrc from "../../../../assets/images/icons/fi_trash.svg"
import trashIconSrc from "../../../../assets/images/icons/fi_trash.svg"
import RemoveServiceModal from "./remove_service_modal"
import RemoveBatchModal from "./remove_batch_modal"
import RemoveBundleModal from "./remove_bundle_modal"
import editSrc from '../../../../assets/images/icons/edit-inline.svg'
import editIcon from "../../../../assets/images/icons/edit.svg"
import Avatar from '../../../../assets/images/avatar.png'
import {formatDatetime} from "../../util/date_util";
import RemoveBundleServiceModal from "./remove_bundle_service_modal"
import editIconSrc from "../../../../assets/images/icons/editing.png"

class EditProjectAdminDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {modal: null}
  }

  onRemoveSubService(e, subService) {
    e.preventDefault()
    const { project } = this.props

    const modal = <RemoveServiceModal project={project}
      subService={subService}
      currentUser={this.props.currentUser}
      fetchCurrentUser={this.props.fetchCurrentUser}
      onClose={() => this.setState({ modal: null })}
      onServiceRemoved={() => location.reload()} />
    this.setState({ modal })
  }
  
  onRemoveBundleSubService(e, subService) {
    e.preventDefault()
    const { project } = this.props

    const modal = <RemoveBundleServiceModal project={project}
      subService={subService}
      onClose={() => this.setState({ modal: null })}
      onServiceRemoved={() => location.reload()}
      currentUser={this.props.currentUser}
      fetchCurrentUser={this.props.fetchCurrentUser} />
    this.setState({ modal })
  }

  onRemoveBundle(e, bundle){
    e.preventDefault()
    const { project } = this.props

    const modal = <RemoveBundleModal project={project}
      bundle={bundle}
      currentUser={this.props.currentUser}
      fetchCurrentUser={this.props.fetchCurrentUser}
      onClose={() => this.setState({ modal: null })}
      onServiceRemoved={() => location.reload()} />

    this.setState({ modal })
  }

  onRemoveBatch(e, batch = null, purchase = null, all_batches = false) {
    e.preventDefault()
    const { project } = this.props

    const modal = <RemoveBatchModal
      batch={batch}
      purchase={purchase}
      project={project}
      all_batches={all_batches}
      onClose={() => this.setState({ modal: null })}
      onServiceRemoved={() => location.reload()} />

    this.setState({ modal })
  }

  navigate(address) {
    window.open(`https://www.google.com/maps/search/?api=1&query=${address.street1.split(" ").join("+")}%2C${address.city.split(" ").join("+")}%2C${address.region}`)
  }
  
  render() {
    const {
      project,
      openReviewModal,
      onAddService,
      openCancelModal,
      openEditAddressModal,
      openAppointmentEditModal,
      currentUser,
    } = this.props

    const canManageContractors = currentUser.roles.admin || currentUser.id === project?.client_id
    const canManageProjectPackages = currentUser.roles.admin || currentUser.id === project?.client_id
    const packagesWithoutContractor = Object.values(project?.packages || []).filter(pack => !pack.contractor && pack.service_title != "Virtual Staging")
    const virtualStagingPurchases = Object.values(project?.virtual_staging_purchases || [])
    const virtualStagingBatches = Object.values(project?.virtual_staging_batches || [])
    const bundle_sub_services = {}

    if (project?.bundle) {
      project?.bundle.sub_services.forEach(sub_service => bundle_sub_services[sub_service.id] = true)
    }

    const photographerPackages = Object.values(project?.packages || []).filter(pack => {
      return pack.contractor?.id === currentUser.id
    })

    const servicesByPhotographer = Object.values(project?.packages || []).reduce((res, pack) => {
      if (res[pack.contractor?.id]) {
        res[pack.contractor.id].packages.push(pack)
      } else {
        const photographer = project?.photographers.find(p => p.id === pack.contractor?.id)
        if (pack.contractor) {
          res[pack.contractor.id] = { photographer, packages: [pack] }
        }
      }
      return res
    }, {})

    const servicesByPhotographerTop = Object.values(project?.packages || []).reduce((res, pack) => {
      if (res[pack.contractor?.id.toString() + pack.appointment_time]) {
        res[pack.contractor?.id.toString() + pack.appointment_time].packages.push(pack)
      } else {
        const photographer = project?.photographers.find(p => p.id === pack.contractor?.id)
        if (pack.contractor) {
          res[pack.contractor.id.toString() + pack.appointment_time] = { photographer, packages: [pack] }
        }
      }
      return res
    }, {})

    let total = Object.values(servicesByPhotographer).flatMap(({packages}) => packages)
      .flatMap(pack => pack.sub_services)
      .reduce((res, sub_service) => res + parseFloat(sub_service.addon ? 0 : project?.invoice_sub_services[sub_service?.id || "invalid"] ? project?.invoice_sub_services[sub_service?.id || "invalid"].price : sub_service.price), 0)

    total += packagesWithoutContractor.flatMap(pack => pack.sub_services).reduce((res, sub_service) => res + parseFloat(project?.invoice_sub_services[sub_service?.id || "invalid"] ? project?.invoice_sub_services[sub_service?.id || "invalid"].price : sub_service.price), 0)
    total += virtualStagingPurchases.reduce((res, batch) => res + parseFloat(batch.price), 0)

    const projectAddress = [
      project?.address?.street1,
      project?.address?.apartment_number,
      project?.address?.city,
      project?.address?.region,
      project?.address?.zip_code
    ].filter(v => !!v).join(', ')

    const shootTimes = Array.from(new Set(Object.values(project?.packages || []).map(pack => {
      return pack.appointment_time
    }))).filter(t => !!t)

    let all_upcoming = true

    Object.values(project?.packages || []).forEach(p => {
      if (p.appointment_time && new Date(p.appointment_time) < Date.now()) {
        all_upcoming = false
      }
    })

    if (this.props.project?.promo_price) {
      total = parseFloat(this.props.project?.promo_price)
      total += virtualStagingPurchases.reduce((res, batch) => res + parseFloat(batch.price), 0)
    }

    if (this.props.project?.discount_bundle_id) {
      total -= this.props.project?.bundle.sub_services.reduce((res, sub_service) => res + parseFloat(project?.invoice_sub_services[sub_service?.id || "invalid"] ? project?.invoice_sub_services[sub_service?.id || "invalid"].price : sub_service.price), 0)
      total += parseFloat(this.props.project?.bundle_price)
    }

    return <div>
      {this.state.modal}
      <div className="editProjectEditTitle">Photoshoot Details</div>
      <div className="editProjectEditSubtitle">
        Project client, photographers and services information
      </div>

      <div className="editProjectPhotographer">
        <div className="photographerCardHeader">
          <div className="photographerCardContainer">
            <div className="d-flex me-2">
              <UserAvatar user={project?.client}/>
              <div className="photographerCardInfo py-1">
                <div className="photographerCardName">{project?.client.first_name} {project?.client.last_name}</div>
                <div className="photographerServices">Client</div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <button className="projectEditButton projectEditButtonPrimary messageButton" onClick={() => window.location = `mailto:${project?.client.email}`}>Message</button>
            </div>
          </div>
        </div>
      </div>

      {Object.values(servicesByPhotographer).map(({photographer, packages}) => {
        return <div className="editProjectPhotographer" key={photographer?.id || 120000000}>
          <div className="photographerCardHeader">
            <div className="photographerCardContainer">
              <div className="d-flex me-2">
                <UserAvatar user={photographer || { first_name: "N", last_name: "A" }}/>
                <div className="photographerCardInfo py-1">
                  <div className="photographerCardName">{photographer?.first_name} {photographer?.last_name}</div>
                  <div className="photographerServices">Photographer</div>
                </div>
              </div>

              <div className="d-flex align-items-center">
                <button className="projectEditButton projectEditButtonPrimary me-2 messageButton" onClick={() => window.location = `mailto:${photographer?.email}`}>Message</button>
                <button className="projectEditButton projectEditButtonLight" onClick={() => this.props.onCancel(photographer.id)}>
                  Decline Job
                </button>
              </div>
            </div>
          </div>
        </div>
      })}

      <div className="editProjectJobInformation">
        <div className="editProjectJobInformationTitle">Job Information</div>
        <table>
          <tbody>
          <tr>
            <td className="editProjectJobInformationLabel">Client Name</td>
            <td className="editProjectJobInformationValue">
              {project?.client.first_name} {project?.client.last_name}
            </td>
          </tr>
          <tr>
            <td className="editProjectJobInformationLabel">Project Address</td>
              <td className="editProjectJobInformationValue"><div className="addressLink" onClick={() => this.navigate(project?.address)}>{project?.address?.formatted_address ? `${project?.address?.apartment_number || ""} ${project?.address?.formatted_address}` : projectAddress}</div></td>
          </tr>
          <tr>
            <td className="editProjectJobInformationLabel">Project Created</td>
            <td className="editProjectJobInformationValue">
              {formatDatetime(project?.created_at, "MM/DD/YYYY, hh:mm A z", project?.client?.timezone)}
            </td>
          </tr>
          <tr>
            <td className="editProjectJobInformationLabel">Shoot Dates</td>
            <td className="editProjectJobInformationValue">
              {shootTimes.map(time => {
                return <div className="mb-1">
                  {formatDatetime(time, "MM/DD/YYYY, hh:mm A z", project?.client?.timezone)}
                </div>
              })}
            </td>
          </tr>
          <tr>
            <td className="editProjectJobInformationLabel">Client Phone</td>
            <td className="editProjectJobInformationValue">{project?.client.phone_number}</td>
          </tr>
          <tr>
            <td className="editProjectJobInformationLabel">Client Email</td>
            <td className="editProjectJobInformationValue">{project?.client.email}</td>
          </tr>
          <tr>
              {canManageProjectPackages ? <td onClick={this.props.openNoteModal} className="editProjectJobInformationLabel cursorPointer">Project Notes</td> : <td className="editProjectJobInformationLabel">Project Notes</td>}
              <td className="editProjectNoteInformation">{project?.notes}</td>
          </tr>
          </tbody>
        </table>
      </div>

      <div className="divider"/>

      <div>
        {Object.values(servicesByPhotographerTop).map(({ photographer, packages }) => {
          // const canEditAppointment = new Date(packages[0].appointment_time) > new Date()
          const canEditAppointment = true

          return <div className="editProjectPhotographer" key={photographer?.id || 120000000}>
            <div className="photographerCardHeader">
              <div className="photographerCardContainer">
                <div className="d-flex me-2">
                  <UserAvatar user={photographer || { first_name: "N", last_name: "A" }} />
                  <div className="photographerCardInfo py-1">
                    <div className="photographerCardName">{photographer?.first_name} {photographer?.last_name}</div>
                    <div className="photographerServices">{packages.map(p => p.service_title).join(', ')}</div>
                  </div>
                </div>

                <div className="d-flex flex-column justify-content-between position-rel">
                  <div className="photographerInfo">
                    {canManageProjectPackages && (
                      <div className="navStartProjectButton smallEditButton"
                        onClick={openEditAddressModal}>Change</div>)}
                    <div className="ms-2">{project?.address.formatted_address ? `${project?.address.apartment_number || ""} ${project?.address?.formatted_address}` : projectAddress}</div>
                  </div>
                  <div className="photographerInfo d-flex">
                    {canManageProjectPackages && canEditAppointment && (
                      <div className="navStartProjectButton smallEditButton"
                        onClick={e => openAppointmentEditModal(e, photographer, packages, Object.values(packages)[0])}>Change</div>
                    )}

                    <div className="ms-2">
                      {formatDatetime(packages[0].appointment_time, "MM/DD/YYYY, hh:mm A z", project?.client?.timezone)}
                    </div>
                  </div>
                </div>

                {/* {canManageContractors && <div className="d-flex align-items-center">
                  <button className="projectEditButton projectEditButtonLight me-2"
                    onClick={e => openReviewModal(photographer)}>
                    {photographer.review_given ? 'Edit Review' : 'Leave Review'}
                  </button>
                  <button className="projectEditButton projectEditButtonLight">Contact</button>
                </div>} */}
              </div>
            </div>
            {/* {canManageContractors && <div className="editProjectPhotographerActions">
              {photographer.review_given && <div>
                <div className="editProjectReviewTitle">Review:</div>
                {photographer.review_given.comment}
              </div>}
            </div>} */}
          </div>
        })}
      </div>

      <div className="divider" />

      <div className="editProjectMap"><Map projects={[project]}/></div>

      <div className="editProjectServices">
        <div className="editProjectServicesHeader">
          <div className="editProjectServicesTitle">Project Services</div>
          {project && <div className="d-flex editProjectServicesButtons">
            <button className="projectEditButton projectEditButtonPrimary" onClick={onAddService}>
              <img src={plusIconSrc} className="me-2"/>
              Add Services
            </button>
            <button className="projectEditButton projectEditButtonLight ms-2" onClick={openCancelModal}>
              <img src={buttonTrashIconSrc} className="me-2"/>
              Cancel Photoshoot
            </button>
          </div>}
        </div>

        <table className="editProjectServicesTable">
          <tbody>
            {packagesWithoutContractor.length > 0 ? <tr className="section-top">
              <td className="servicesPhotographerAvatarColumn">
                <UserAvatar user={{ first_name: "!!!", last_name: "!!!!", avatar: Avatar }} />
              </td>
              <td colSpan={3} className="servicesPhotographerNameColumn NeedsReschedulingColumn">
                <div className="photographerCardName">Needs Rescheduling</div>
              </td>
              <td className="row-filler" />
            </tr> : ""}
            {packagesWithoutContractor.map((pack, i) => {
              return <React.Fragment key={`photographer-${pack.id}`}>
                <tr>
                  <td className="servicesPhotographerAvatarColumn">
                    {/* <div>Needs Rescheduling</div> */}
                  </td>
                  <td colSpan={3} className="servicesPhotographerNameColumn packageReschedule">
                    <div>{pack.service_title} {canManageProjectPackages && (
                      <div className="rescheduleButtonSmall"
                        onClick={e => openAppointmentEditModal(e, null, packagesWithoutContractor, pack)}>Reschedule</div>
                    )}</div>
                  </td>
                  <td className="row-filler" />
                </tr>

                {pack.sub_services.map((subService, j) => {
                  const invoice_sub_service_price = project?.invoice_sub_services[subService?.id || "invalid"] ? project?.invoice_sub_services[subService?.id || "invalid"].price : subService.price
                  return <tr key={`sub-service-${subService.id}`}
                    className={i === packagesWithoutContractor.length - 1 && j === pack.sub_services.length - 1 ? 'section-bottom' : ''}>
                    <td />
                    <td className="hideMobile">{pack.service_title}</td>
                    <td>{subService.title}</td>
                    {bundle_sub_services[subService.id] || subService.addon ? subService.addon ? <td>{project?.bundle?.title} Addon {canManageProjectPackages &&
                      <img src={trashIconSrc} className="cursor-pointer sub-service-trash"
                        onClick={(e) => this.onRemoveSubService(e, subService)} />}</td> : <td>{project?.bundle?.title} {canManageProjectPackages &&
                          <img src={trashIconSrc} className="cursor-pointer sub-service-trash"
                        onClick={(e) => this.onRemoveBundleSubService(e, subService)} />}</td> : <td className="text-muted d-flex"><div className="price-text">${parseFloat(invoice_sub_service_price).toFixed(2)}</div> {canManageProjectPackages &&
                      <img src={trashIconSrc} className="cursor-pointer sub-service-trash"
                        onClick={(e) => this.onRemoveSubService(e, subService)} />}</td>}
                    <td className="row-filler" />
                  </tr>
                })}
              </React.Fragment>
            })}
          {Object.values(servicesByPhotographer).map(({photographer, packages}) => {
            return <React.Fragment key={`photographer-${photographer?.id || 120000000}`}>
              <tr className="section-top">
                <td className="servicesPhotographerAvatarColumn">
                  <UserAvatar user={photographer || { first_name: "N", last_name: "A" }} />
                </td>
                <td colSpan={3} className="servicesPhotographerNameColumn">
                  <div className="photographerCardName">{photographer?.first_name} {photographer?.last_name}</div>
                </td>
                <td className="row-filler"/>
              </tr>

              {packages.map((pack, i) => {
                return pack.sub_services.map((subService, j) => {
                  const contractorSubServicesHash = photographer?.contractor_sub_services?.reduce((acc, contractorSubService) => {
                    acc[contractorSubService.sub_service_id] = contractorSubService;
                    return acc;
                  }, {});
                  const invoice_sub_service_price = project?.invoice_sub_services[subService?.id || "invalid"] ? project?.invoice_sub_services[subService?.id || "invalid"].price : subService.price
                  return <tr key={`sub-service-${subService.id}`}
                             className={i === packages.length - 1 && j === pack.sub_services.length - 1 ? 'section-bottom' : ''}>
                    <td/>
                    <td>{pack.service_title}</td>
                    <td>{subService.title}</td>
                    {bundle_sub_services[subService.id] || subService.addon ? subService.addon ? <td>{project?.bundle?.title} Addon {canManageProjectPackages &&
                      <img src={trashIconSrc} className="cursor-pointer sub-service-trash"
                        onClick={(e) => this.onRemoveSubService(e, subService)} />}</td> : <td>{project?.bundle?.title} {canManageProjectPackages &&
                          <img src={trashIconSrc} className="cursor-pointer sub-service-trash"
                            onClick={(e) => this.onRemoveBundleSubService(e, subService)} />}</td> : project?.promo_price ? <td></td> : <td className="d-flex text-muted"><div className="price-text">${parseFloat(invoice_sub_service_price).toFixed(2)}</div>
                      {project?.promo_price ? "" : <div className="navStartProjectButton smallUpgradeButton" onClick={() => this.props.onChangeService(pack.service_id)}>Upgrade</div>}
                      <img src={trashIconSrc} className="cursor-pointer sub-service-trash-2" onClick={(e) => this.onRemoveSubService(e, subService)} /></td>}
                    <td className="text-muted"><div className="price-text">${contractorSubServicesHash ? contractorSubServicesHash[subService.id]?.payout || subService.contractor_payout : subService.contractor_payout}</div></td>
                    <td className="row-filler"/>
                  </tr>
                })
              })}
            </React.Fragment>
          })}
            {virtualStagingPurchases.length > 0 ? <tr className="section-top">
              <td className="servicesPhotographerAvatarColumn">
                {virtualStagingPurchases.length > 0 ? <UserAvatar user={{ first_name: "Virtual", last_name: "Staging" }} /> : ""}
              </td>
              <td colSpan={3} className="servicesPhotographerNameColumn">
                <div className="photographerCardName">{virtualStagingPurchases.length > 0 ? "Virtual Staging" : ""}<img src={trashIconSrc} className="cursor-pointer sub-service-trash-2 trashIconSpecial" onClick={(e) => this.onRemoveBatch(e, null, null, true)} /></div>
              </td>
              <td className="row-filler" />
            </tr>
              : ""}

            {virtualStagingPurchases.map((purchase, i) => {
              return <tr className="section-bottom">
                <td className="">
                  <div>{purchase.virtual_suite_type?.name}</div>
                </td>
                <td className="">
                  <div>{purchase.category?.name}</div>
                </td>
                {/* <td className="">
                   <div>{purchase.product.name}</div>
                 </td> */}
                <td>{Object.values(purchase.photos).length} {Object.values(purchase.photos).length > 1 ? "Photos" : "Photo"}</td>
                <td className="d-flex"><div className="price-text">${parseFloat(purchase.price).toFixed(2)}</div><img src={trashIconSrc} className="cursor-pointer sub-service-trash-2 trashIconSpecial" onClick={(e) => this.onRemoveBatch(e, purchase.batch, purchase)} /></td>
                <td className="row-filler" />
              </tr>
            })}
            {project?.promo_price ?
              <tr className="">
                <td />
                <td className="">Promo:</td>
                <td className="">{project?.promo_name}</td>
                <td className="">${parseFloat(project?.promo_price).toFixed(2)}</td>
              </tr>
              : ""}
            {project?.discount_bundle_id ?
              <tr className="">
                <td />
                <td className="">Bundle:</td>
                <td className="">{project?.bundle?.title}</td>
                <td className="text-muted d-flex">${project?.bundle_price}  
                {/* <div className="navStartProjectButton smallUpgradeButton" onClick={() => this.props.onChangeBundle()}>Upgrade</div> */}
                <img src={trashIconSrc} className="cursor-pointer sub-service-trash-2 trashIconSpecial" onClick={(e) => this.onRemoveBundle(e, project?.bundle)} />
                </td>
              </tr>
              : ""}
            <tr className="">
              <td />
              <td />
              <td className="font-bold">Total:</td>
              <td className="font-bold">${parseInt(total)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  }
}

export default EditProjectAdminDetails