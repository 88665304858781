import React, {useState} from 'react'
import areaImg from "../../../../../assets/images/photographerOnboarding/area.svg"
import {Footer} from "../footer";
import {updatePhotographerArea} from "../../../util/photographer_onboarding_util";

export const Area = (props) => {
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])

  const [data, setData] = useState({...{
    primaryZipCode: '',
    distance: 20
  }, ...props.data})

  const onSubmit = (e) => {
    e.preventDefault()

    const payload = {
      primary_zip_code: data.primaryZipCode,
      service_radius: data.distance
    }

    setLoading(true)

    updatePhotographerArea({user: payload}).then(result => {
      setLoading(false)
      props.onNext(data)
    }).catch(response => {
      setLoading(false)
      setErrors(response.responseJSON || [])
    })
  }

  const onChange = (field) => {
    return (e) => {
      setData({...data, [field]: e.target.value})
    }
  }

  return <form onSubmit={onSubmit}>
    <div className="photographer-onboarding-form-title">
      What is your service <span className="blue">area?</span>
    </div>

    <div className="photographer-onboarding-center-image-wrapper">
      <img src={areaImg} className="photographer-onboarding-area-image-circles"/>
    </div>

    {errors.length > 0 && <div className="alert alert-danger mb-4">
      {errors.map((err, i) => (<div key={`error-${i}`}>{err}</div>))}
    </div>}

    <div className="photographer-onboarding-form-fields">
      <div className="photographer-onboarding-form-field">
        <label id="formLabel" className="formLabel required">Primary Zip Code</label>
        <input className="formInput" placeholder="Content" value={data.primaryZipCode}
               onInput={onChange('primaryZipCode')} required={true} />
      </div>

      <div className="photographer-onboarding-form-field">
        <label id="formLabel" className="formLabel required">Distance</label>
        <select value={data.distance} onChange={onChange('distance')} className="formInput">
          <option value={10}>10 Miles</option>
          <option value={20}>20 Miles</option>
          <option value={30}>30 Miles</option>
          <option value={40}>40 Miles</option>
          <option value={50}>50 Miles</option>
        </select>
      </div>
    </div>

    <Footer onBack={props.onBack} nextDisabled={loading}/>
  </form>
}