import React from "react";
import Logo from "../../../../assets/images/logo.svg";
import NavbarContainer from "../navbar/navbar_container";
import {Link} from "react-router-dom";
import CareersApplyForm from "./careers_apply_form";
import {connect} from "react-redux";


const CareersApply = ({currentUser}) => {
  return (
    <>
      {/* <div className="z-2">
        <NavbarContainer />
      </div> */}

      <div className="loginBackgroundDesignCareers"/>

      <div className={`loginContainer careersContainer ${currentUser ? 'loggedIn' : ''}`}>
        <div className="d-flex justify-content-center w-100">
          <div className="careersForm">
            <Link to="/">
              <img className="modernAnglesLoginLogo" src={Logo} onClick={() => this.props.history.push("/")}/>
            </Link>
            <CareersApplyForm/>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser ? state.entities.users[state.session.currentUser.id] : null
});

export default connect(mapStateToProps)(CareersApply);