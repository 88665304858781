import React from "react";
import {withRouter} from "react-router-dom";
import debounce from 'debounce'
import ProjectsIndexProjectsItem from "./projects_index_projects_item";
import ProjectsIndexProjectsNonGridItem from "./projects_index_projects_non_grid_item";
import {fetchUserProjects, fetchCompletedProjects, fetchUpcomingProjects, fetchReadyProjects} from "../../util/project_api_util";
import gridIconSelected from "../../../../assets/images/icons/gridIconSelected";
import rowIconSelected from "../../../../assets/images/icons/rowIconSelected";
import Select from 'react-select'
import { fetchAllSubBrokers } from "../../util/sub_brokers_api_util";
import { fetchUsersNameAndIdOnly } from "../../util/users_api_util";
import LogoSpinner from "../../../../assets/images/landing/video-thumbnails/MA-logo-spinner-transparent.gif";


class ProjectsIndexProjects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navActive: 1,
      streamAmount: 6,
      completedStreamAmount: 1,
      upcomingStreamAmount: 1,
      readyStreamAmount: 10,
      InProductionStreamAmount: 10,
      gridOpenInProgress: true,
      gridOpenCompleted: this.props.isLargeScreen ? false : true,
      gridOpenReady: true,
      gridOpenInProduction: true,
      searchUser: "",
      search: "",
      searchInProgress: "",
      searchReady: "",
      searchCompleted: "",
      searchInProduction: "",
      projectType: "",
      projects: this.props.projects,
      all_projects: this.props.projects,
      upcoming_projects: [],
      ready_projects: [],
      in_production_projects: [],
      completed_projects: [],
      original_upcoming_projects: [],
      original_ready_projects: [],
      original_completed_projects: [],
      original_in_production_projects: [],
      loading: false,
      loading_projects: false,
      all_loading: false,
      upcoming_loading: false,
      ready_loading: false,
      completed_loading: false,
      in_production_loading: false,
    }
    this.updateNavActive = this.updateNavActive.bind(this)
    this.onSearchChange = this.onSearchChange.bind(this)
    this.streamMoreProjects = this.streamMoreProjects.bind(this)
    this.onUserChange = this.onUserChange.bind(this)
    this.debounceFetchProjects = debounce(() => this.fetchProjects(), 800)
    this.fetchUsersForDropdown = this.fetchUsersForDropdown.bind(this)
    this.abortController = new AbortController()
    this.onUserSearchChange = this.onUserSearchChange.bind(this)
    this.debounceFetchUsersForDropdown = debounce(() => this.fetchUsersForDropdown(), 500)
  }

  componentDidMount(){
    window.scrollTo(0, 0)
    if (this.props.projects.length === 0) {
      if (this.props.currentUser.roles.admin || this.props.currentUser.roles.contractor || this.props.currentUser.roles.post_production){
        this.setState({ ready_loading: true })
        fetchReadyProjects(this.props.currentUser.roles.admin ? "all-users" : this.props.currentUser.roles.broker ? "all-sub-brokers" : this.props.currentUser.id, null, this.state.readyStreamAmount).then(projects => {
          this.setState({ ready_projects: projects, original_ready_projects: projects, ready_loading: false })
        })
      }

      this.setState({upcoming_loading: true})
      fetchUpcomingProjects(this.props.currentUser.roles.admin ? "all-users" : this.props.currentUser.roles.broker ? "all-sub-brokers": this.props.currentUser.id, null, this.state.upcomingStreamAmount).then(projects => {
        this.setState({ upcoming_projects: projects, original_upcoming_projects: projects, upcoming_loading: false})
      })

      this.setState({ completed_loading: true })
      fetchCompletedProjects(this.props.currentUser.roles.admin ? "all-users" : this.props.currentUser.roles.broker ? "all-sub-brokers" : this.props.currentUser.id, null, this.state.completedStreamAmount).then(projects => {
        this.setState({ completed_projects: projects, original_completed_projects: projects, completed_loading: false })
      })

      if (this.props.currentUser.roles.contractor){
        this.setState({ in_production_loading: true })
        fetchReadyProjects(this.props.currentUser.id, null, this.state.InProductionStreamAmount, true).then(projects => {
          this.setState({ in_production_projects: projects, original_in_production_projects: projects, in_production_loading: false })
        })
      }
    }
  }

  componentDidUpdate(_, prevState) {
    if (prevState.searchInProgress !== this.state.searchInProgress || prevState.searchCompleted !== this.state.searchCompleted || prevState.searchReady !== this.state.searchReady) {
      this.debounceFetchProjects()
    }
    
    if (prevState.searchUser !== this.state.searchUser) {
      if (this.state.searchUser === ""){
        if (this.props.currentUser?.roles?.broker) {
          this.props.changeParentState({subBrokers: []})
        } else {
          this.props.changeParentState({users: []})
        }
      } else if (this.state.searchUser.length > 2){
        this.debounceFetchUsersForDropdown()
      }
    }
  }

  onUserSearchChange(newValue) {
    this.setState({ searchUser: newValue })
  }

  fetchUsersForDropdown() {
    if (this.props.currentUser?.roles?.broker) {
      this.setState({loading: true})
      fetchAllSubBrokers().then(subBrokers => {
        this.props.changeParentState({ subBrokers: Object.values(subBrokers) })
        this.setState({loading: false})
      })
    }

    if (this.props.currentUser?.roles?.admin && this.state.searchUser != "") {
      this.setState({loading: true})
      fetchUsersNameAndIdOnly(this.state.searchUser).then(users => {
        this.props.changeParentState({ users: Object.values(users) })
        this.setState({loading: false})
      })
    }
  }

  fetchProjects(user = null) {
    const { search } = this.state

    if (user) {
      this.setState({ upcoming_loading: true })
      fetchUpcomingProjects(this.props.userIdOfProjects, null, this.state.upcomingStreamAmount).then(projects => {
        this.setState({ upcoming_projects: projects, original_upcoming_projects: projects, upcoming_loading: false })
      })

      this.setState({ ready_loading: true })
      fetchReadyProjects(this.props.userIdOfProjects, null, this.state.readyStreamAmount).then(projects => {
        this.setState({ ready_projects: projects, original_ready_projects: projects, ready_loading: false })
      })
      
      this.setState({ completed_loading: true })
      fetchCompletedProjects(this.props.userIdOfProjects, null, this.state.completedStreamAmount).then(projects => {
        this.setState({ completed_projects: projects, original_completed_projects: projects, completed_loading: false })
      })

      this.setState({ in_production_loading: true })
      fetchReadyProjects(this.props.userIdOfProjects, null, this.state.InProductionStreamAmount, true).then(projects => {
        this.setState({ in_production_projects: projects, original_in_production_projects: projects, in_production_loading: false })
      })
    } else {
      if (search != "") {
        if (this.state.searchInProgress != "") {
          this.setState({ upcoming_loading: true })
          fetchUpcomingProjects(this.props.userIdOfProjects, search, this.state.upcomingStreamAmount).then(projects => {
            this.setState({ upcoming_projects: projects, upcoming_loading: false })
          })
        }
        
        if (this.state.searchReady != "") {
          this.setState({ ready_loading: true })
          fetchReadyProjects(this.props.userIdOfProjects, search, this.state.readyStreamAmount).then(projects => {
            this.setState({ ready_projects: projects, ready_loading: false })
          })
        }

        if (this.state.searchCompleted != "") {
          this.setState({ completed_loading: true })
          fetchCompletedProjects(this.props.userIdOfProjects, search, this.state.completedStreamAmount).then(projects => {
            this.setState({ completed_projects: projects, completed_loading: false })
          })
        }

        if (this.state.searchInProduction != "") {
          this.setState({ in_production_loading: true })
          fetchReadyProjects(this.props.userIdOfProjects, search, this.state.InProductionStreamAmount, true).then(projects => {
            this.setState({ in_production_projects: projects, in_production_loading: false })
          })
        }
      }
    }
  }

  streamMoreProjects(){
    this.setState({streamAmount: this.state.streamAmount + 1})
  }

  updateNavActive(num){
    this.setState({navActive: num})
  }

  onUserChange(e) {
    this.props.onUserChange(e.value).then(() => this.fetchProjects(true))
  }

  onSearchChange(event) {
    event.preventDefault()
    const { name, value } = event.target
    if (name === "searchInProgress"){
      this.setState({ [name]: value, searchReady: "", searchCompleted: "", searchInProduction: "", search: value})
    } else if (name === "searchReady"){
      this.setState({ [name]: value, searchInProgress: "", searchCompleted: "", searchInProduction: "", search: value })
    } else if (name === "searchCompleted"){
      this.setState({ [name]: value, searchInProgress: "", searchReady: "", searchInProduction: "", search: value })
    } else if (name === "searchInProduction") {
      this.setState({ [name]: value, searchInProgress: "", searchCompleted: "", searchReady: "", search: value })
    }
  }

  render() {
    let navActive = ["","",""]
    let featuredText = "";
    let inProductionText = "";
    let readyProjects = ""
    let inProductionProjects = ""

    let projectIndexProjectsInProgressUl = "";
    let projectIndexProjectsCompletedUl = "";
    let projectIndexProjectsReadyUl = "";
    let projectIndexProjectsInProductionUl = "";

    let gridIconInProgress = <div key="1" id={this.state.gridOpenInProgress ? "activeIconDiv" : "inactiveIconDiv"} onClick={() => this.setState({ gridOpenInProgress: true })}><img className={this.state.gridOpenInProgress ? " activeIcon" : "inactiveIcon"}  src={gridIconSelected} style={{ marginLeft: 20, marginRight: 0 }}></img></div>
    let gridIconCompleted = <div key="2" id={this.state.gridOpenCompleted ? "activeIconDiv" : "inactiveIconDiv"} onClick={() => this.setState({ gridOpenCompleted: true })}><img className={this.state.gridOpenCompleted ? " activeIcon" : "inactiveIcon"}  src={gridIconSelected} style={{ marginLeft: 20, marginRight: 0 }}></img></div>
    let gridIconReady = <div key="5" id={this.state.gridOpenReady ? "activeIconDiv" : "inactiveIconDiv"} onClick={() => this.setState({ gridOpenReady: true })}><img className={this.state.gridOpenReady ? " activeIcon" : "inactiveIcon"}  src={gridIconSelected} style={{ marginLeft: 20, marginRight: 0 }}></img></div>
    let gridIconInProduction = <div key="7" id={this.state.gridOpenInProduction ? "activeIconDiv" : "inactiveIconDiv"} onClick={() => this.setState({ gridOpenInProduction: true })}><img className={this.state.gridOpenInProduction ? " activeIcon" : "inactiveIcon"} src={gridIconSelected} style={{ marginLeft: 20, marginRight: 0 }}></img></div>
    let rowIconInProgress = <div key="3" id={this.state.gridOpenInProgress ? "inactiveIconDiv" : "activeIconDiv"} onClick={() => this.setState({ gridOpenInProgress: false })}><img className={this.state.gridOpenInProgress ? " inactiveIcon" : "activeIcon"}  src={rowIconSelected} style={{ marginLeft: 20, marginRight: 0 }}></img></div>
    let rowIconCompleted = <div key="4" id={this.state.gridOpenCompleted ? "inactiveIconDiv" : "activeIconDiv"} onClick={() => this.setState({ gridOpenCompleted: false })}><img className={this.state.gridOpenCompleted ? " inactiveIcon" : "activeIcon"} src={rowIconSelected} style={{ marginLeft: 20, marginRight: 0 }}></img></div>
    let rowIconReady = <div key="6" id={this.state.gridOpenReady ? "inactiveIconDiv" : "activeIconDiv"} onClick={() => this.setState({ gridOpenReady: false })}><img className={this.state.gridOpenReady ? " inactiveIcon" : "activeIcon"}  src={rowIconSelected} style={{ marginLeft: 20, marginRight: 0 }}></img></div>
    let rowIconInProduction = <div key="8" id={this.state.gridOpenInProduction ? "inactiveIconDiv" : "activeIconDiv"} onClick={() => this.setState({ gridOpenInProduction: false })}><img className={this.state.gridOpenInProduction ? " inactiveIcon" : "activeIcon"} src={rowIconSelected} style={{ marginLeft: 20, marginRight: 0 }}></img></div>

    let upcomingProjectList = this.state.original_upcoming_projects
    let completedProjectList = this.state.original_completed_projects
    let readyProjectList = this.state.original_ready_projects
    let inProductionProjectList = this.state.original_in_production_projects

    if (!this.props.isLargeScreen && !this.state.gridOpenCompleted){
      this.setState({gridOpenCompleted: true})
    }

    if (!this.props.isLargeScreen && !this.state.gridOpenInProgress) {
      this.setState({ gridOpenInProgress: true })
    }

    if (!this.props.isLargeScreen && !this.state.gridOpenReady) {
      this.setState({ gridOpenReady: true })
    }

    if (!this.props.isLargeScreen && !this.state.gridOpenInProduction) {
      this.setState({ gridOpenInProduction: true })
    }
    
    if (this.state.searchInProgress != ""){
      upcomingProjectList = this.state.upcoming_projects
    }

    if (this.state.searchReady != "") {
      readyProjectList = this.state.ready_projects
    }

    if (this.state.searchCompleted != "") {
      completedProjectList = this.state.completed_projects
    }

    if (this.state.searchInProduction != ""){
      inProductionProjectList = this.state.in_production_projects
    }
    
    let upcomingProjects = upcomingProjectList
    let upcomingProjectsTotal = upcomingProjectList.length

    // if (this.props.currentUser.roles.admin){
    //   upcomingProjectsTotal = upcomingProjectList?.filter(project => project.status === "booked" && !project.ready_for_download).length
    //   upcomingProjects = upcomingProjectList?.filter(project => project.status === "booked" && !project.ready_for_download)?.sort((a, b) => { return new Date(a.upcoming_appointment_time).getTime() - new Date(b.upcoming_appointment_time).getTime()}).slice(0, this.state.streamAmount) || []
    // } else if (this.props.currentUser.roles.post_production){
    //   upcomingProjectsTotal = upcomingProjectList?.filter(project => project.status === "booked" && !project.ready_for_download_production && !project.hide_from_production).length
    //   upcomingProjects = upcomingProjectList?.filter(project => project.status === "booked" && !project.ready_for_download_production && !project.hide_from_production)?.sort((a, b) => { return new Date(a.upcoming_appointment_time).getTime() - new Date(b.upcoming_appointment_time).getTime() }).slice(0, this.state.streamAmount) || []
    // }else if (this.props.currentUser.roles.contractor){
    //   upcomingProjects = upcomingProjectList?.filter(project => project.status === "booked" && !project.ready_for_upload && !project.in_production).sort((a, b) => { return new Date(a.upcoming_appointment_time).getTime() - new Date(b.upcoming_appointment_time).getTime() }) || []
    // } else {
    //   upcomingProjects = upcomingProjectList?.filter(project => project.status === "booked").sort((a, b) => { return new Date(a.upcoming_appointment_time).getTime() - new Date(b.upcoming_appointment_time).getTime() }) || []
    // }
    if (this.state.gridOpenInProgress){
      projectIndexProjectsInProgressUl = upcomingProjects.map((project, i) => <ProjectsIndexProjectsItem key={i} project={project} currentUser={this.props.currentUser}/>)
    } else {
      projectIndexProjectsInProgressUl = upcomingProjects.map((project, i) => <ProjectsIndexProjectsNonGridItem key={i} project={project} currentUser={this.props.currentUser}/>)
    }

    let previousProjects = completedProjectList

    if (this.state.gridOpenCompleted) {
      projectIndexProjectsCompletedUl = previousProjects.map((project, i) => <ProjectsIndexProjectsItem key={i} project={project} currentUser={this.props.currentUser} />)
    } else {
      projectIndexProjectsCompletedUl = previousProjects.map((project, i) => <ProjectsIndexProjectsNonGridItem key={i} project={project} currentUser={this.props.currentUser} />)
    }

    readyProjects = readyProjectList 

    if (this.props.currentUser.roles.admin){
      featuredText = "Ready"
    } else if (this.props.currentUser.roles.post_production){
      featuredText = "Ready"
    }else if (this.props.currentUser.roles.contractor){
      featuredText = "Ready For Upload";
      inProductionText = "In Progress"
      inProductionProjects = inProductionProjectList
      
      if (this.state.gridOpenInProduction) {
        projectIndexProjectsInProductionUl = inProductionProjects.map((project, i) => <ProjectsIndexProjectsItem key={i} project={project} currentUser={this.props.currentUser} />)
      } else {
        projectIndexProjectsInProductionUl = inProductionProjects.map((project, i) => <ProjectsIndexProjectsNonGridItem key={i} project={project} currentUser={this.props.currentUser} />)
      }
    } else {
    }

    if (this.state.gridOpenReady) {
      projectIndexProjectsReadyUl = readyProjects.map((project, i) => <ProjectsIndexProjectsItem key={i} project={project} currentUser={this.props.currentUser} />)
    } else {
      projectIndexProjectsReadyUl = readyProjects.map((project, i) => <ProjectsIndexProjectsNonGridItem key={i} project={project} currentUser={this.props.currentUser} />)
    }

    let searchBarInProgress = <div className="searchBarDiv"><input className="formInput" name="searchInProgress" placeholder="Search" value={this.state.searchInProgress}
      onChange={this.onSearchChange} />
    </div>

    let searchBarCompleted = <div className="searchBarDiv"><input className="formInput" name="searchCompleted" placeholder="Search" value={this.state.searchCompleted}
      onChange={this.onSearchChange} />
    </div>

    let searchBarReady = <div className="searchBarDiv"><input className="formInput" name="searchReady" placeholder="Search" value={this.state.searchReady}
      onChange={this.onSearchChange} />
    </div>

    let searchBarInProduction = <div className="searchBarDiv"><input className="formInput" name="searchInProduction" placeholder="Search" value={this.state.searchInProduction}
      onChange={this.onSearchChange} />
    </div>

    let inProductionProjectDiv = ""
    if (this.props.currentUser.roles.contractor){
      inProductionProjectDiv = <div className="projectIndexSection">
        <div className="projectIndexSectionHeading">{inProductionText}</div>
        <div className="projectIndexSubfeatures">
          {searchBarInProduction}
          <div className="projectIndexGridRowIcons">
            {gridIconInProduction}
            {rowIconInProduction}
          </div>
        </div>
        <div className="projectIndexProjectsUl">
          {projectIndexProjectsInProductionUl}
        </div>
      </div>
    }

    let usersDropdown = ""


    if (this.props.currentUser?.roles?.admin || this.props.currentUser?.roles?.broker) {
      const usersOptions = [{ value: this.props.currentUser.id, label: "All Users" }]

      this.props.users.forEach(user => {
        usersOptions.push({ value: user.id, label: `${user.email} - ${user.total_projects}` })
      })
    
      usersDropdown = <Select className="ml-auto projectsIndexSubBrokerSelect" isLoading={this.state.loading} defaultValue={{ value: "all-users", label: "All Users" }} options={usersOptions} onChange={this.onUserChange } onInputChange = {(newValue) => this.onUserSearchChange(newValue)} />
    }

    if (this.props.subBrokers.length > 0) {
      const brokersOptions = [{ value: "all-sub-brokers", label: "All Projects" }, { value: this.props.currentUser.id, label: "Personal Projects" }]
      this.props.subBrokers.forEach(user => {
        brokersOptions.push({ value: user.id, label: `${user.firstName} ${user.lastName}`})
      })
      usersDropdown = <Select className="ml-auto projectsIndexSubBrokerSelect" isLoading={this.state.loading} defaultValue = {{ value: "all-sub-brokers", label: "All Projects" }} options = { brokersOptions } onChange = { this.onUserChange } />
    }

    if (this.state.ready_loading){
      projectIndexProjectsReadyUl = <div className="createProjectLogoSpinner">
        <img className="logoImageSpinnerGif" src={LogoSpinner}></img>
      </div>
    }
    if (this.state.completed_loading) {
      projectIndexProjectsCompletedUl = <div className="createProjectLogoSpinner">
        <img className="logoImageSpinnerGif" src={LogoSpinner}></img>
      </div>
    } 
    if (this.state.upcoming_loading) {
      projectIndexProjectsInProgressUl = <div className="createProjectLogoSpinner">
        <img className="logoImageSpinnerGif" src={LogoSpinner}></img>
      </div>
    }

    if (this.state.in_production_loading){
      projectIndexProjectsInProductionUl = <div className = "createProjectLogoSpinner" >
        <img className="logoImageSpinnerGif" src={LogoSpinner}></img>
      </div>
    }

    return (
      <div>
        {usersDropdown}
        {featuredText && <div className="projectIndexSection">
          <div className="projectIndexSectionHeading">{featuredText}</div>
          <div className="projectIndexSubfeatures">
            {searchBarReady}
            <div className="projectIndexGridRowIcons">
              {gridIconReady}
              {rowIconReady}
            </div>
          </div>
          <div className="projectIndexProjectsUl">
            {projectIndexProjectsReadyUl}
          </div>
          {projectIndexProjectsReadyUl.length === 5 * this.state.readyStreamAmount ? <div className="streamingArrowProjectsIndex" onClick={() => fetchReadyProjects(this.props.userIdOfProjects, null, this.state.readyStreamAmount + 1).then(projects => this.setState({ ready_projects: projects, original_ready_projects: projects, readyStreamAmount: this.state.readyStreamAmount + 1 }))}><i className="fas fa-chevron-down"></i></div> : ""}
         </div>
        }
        <div className="projectIndexSection">
          <div className="projectIndexSectionHeading">Upcoming</div>
          <div className="projectIndexSubfeatures">
            {searchBarInProgress}
            <div className="projectIndexGridRowIcons">
              {gridIconInProgress}
              {rowIconInProgress}
            </div>
          </div>
          <div className="projectIndexProjectsUl">
            {projectIndexProjectsInProgressUl}
          </div>
          {projectIndexProjectsInProgressUl.length === 6 * this.state.upcomingStreamAmount ? <div className="streamingArrowProjectsIndex" onClick={() => fetchUpcomingProjects(this.props.userIdOfProjects, null, this.state.upcomingStreamAmount + 1).then(projects => this.setState({ upcoming_projects: projects, original_upcoming_projects: projects, upcomingStreamAmount: this.state.upcomingStreamAmount + 1 }))}><i className="fas fa-chevron-down"></i></div> : ""}
        </div>
        {inProductionProjectDiv}
        <div className="projectIndexSection">
          <div className="projectIndexSectionHeading">Completed</div>
          <div className="projectIndexSubfeatures">
            {searchBarCompleted}
            <div className="projectIndexGridRowIcons">
              {gridIconCompleted}
              {rowIconCompleted}
            </div>
          </div>
          <div className="projectIndexProjectsUl">
            {projectIndexProjectsCompletedUl}
          </div>
          {projectIndexProjectsCompletedUl.length === 5*this.state.completedStreamAmount ? <div className="streamingArrowProjectsIndex" onClick={() => fetchCompletedProjects(this.props.userIdOfProjects, null, this.state.completedStreamAmount+1).then(projects => this.setState({completed_projects: projects, original_completed_projects: projects, completedStreamAmount: this.state.completedStreamAmount + 1}))}><i className="fas fa-chevron-down"></i></div> : ""}
        </div>
      </div>
    )
  }
}

export default withRouter(ProjectsIndexProjects);