export const fetchService = (service_id) => (
  $.ajax({
    type: "GET",
    url: "/fetch_service",
    data: { service_id: service_id }
  })
);

export const fetchServices = () => (
  $.ajax({
    type: "GET",
    url: "/fetch_all_services",
  })
);

export const fetchSubServices = () => (
  $.ajax({
    type: "GET",
    url: "/sub_services",
  })
);

export const fetchServicesSettings = () => (
  $.ajax({
    type: "GET",
    url: "/fetch_all_services_settings",
  })
);

export const fetchLocationSubServices = (location) => (
  $.ajax({
    type: "GET",
    url: "/location_sub_service_prices",
    data: {location: location}
  })
);

export const fetchLocations = () => (
  $.ajax({
    type: "GET",
    url: "/fetch_locations",
  })
);

export const createService = (service_info) => (
  $.ajax({
    type: "POST",
    url: "/services",
    data: {service: service_info}
  })
);

export const updateService = (service_info, service_id) => (
  $.ajax({
    type: "PATCH",
    url: `/services/${service_id}`,
    data: { service: service_info }
  })
);

export const deleteService = (service_id) => (
  $.ajax({
    type: "DELETE",
    url: `/services/${service_id}`,
  })
);

export const createSubService = (sub_service_info) => (
  $.ajax({
    type: "POST",
    url: "/sub_services",
    data: { sub_service: sub_service_info }
  })
);

export const updateSubService = (sub_service_info, sub_service_id) => (
  $.ajax({
    type: "PATCH",
    url: `/sub_services/${sub_service_id}`,
    data: { sub_service: sub_service_info }
  })
);

export const deleteSubService = (sub_service_id) => (
  $.ajax({
    type: "DELETE",
    url: `/sub_services/${sub_service_id}`,
  })
);

export const createGallery = (gallery_info) => (
  $.ajax({
    type: "POST",
    url: "/galleries",
    data: gallery_info,
    cache: false,
    contentType: false,
    processData: false,
  })
);

export const updateGallery = (gallery_info, gallery_id) => (
  $.ajax({
    type: "PATCH",
    url: `/galleries/${gallery_id}`,
    data: gallery_info,
    cache: false,
    contentType: false,
    processData: false,
  })
);


export const deleteGallery = (gallery_id) => (
  $.ajax({
    type: "DELETE",
    url: `/galleries/${gallery_id}`,
  })
);

export const fetchContractors = (service_id, zipcode, search = null, date = null, time = null, services = null, appointments = null, timezone = undefined, photographer_index = null) => (
  $.ajax({
    type: "GET",
    url: "/service_contractors",
    data: { service_id: service_id, zipcode: zipcode, search: search, date: date, time: time, services: services, appointments, timezone: timezone, photographer_index: photographer_index }
  })
);

export const fetchPhotographer = (id) => (
  $.ajax({
    type: "GET",
    url: "/fetch_photographer",
    data: {id: id }
  })
);

export const fetchPhotographerWithReviews = (id) => (
  $.ajax({
    type: "GET",
    url: "/fetch_photographer_with_reviews",
    data: { id: id }
  })
);

