import React from "react";
import { debounce } from "debounce";
import { Route, Link, withRouter } from "react-router-dom";
import { fetchAllSubBrokers } from "../../util/sub_brokers_api_util";
import { fetchUsersNameAndIdOnly} from "../../util/users_api_util";
import UserAvatar from "../photographers/photograher_avatar";
import Select from 'react-select'
import { applyCoupon } from "../../util/discount_code_api_utils";
import {formatDatetime} from "../../util/date_util";
import LogoSpinner from "../../../../assets/images/landing/video-thumbnails/MA-logo-spinner-transparent.gif";

class OrderSummaryComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      new_email: "",
      emailError: "",
      couponError: "",
      couponSuccess: "",
      code: this.props.coupon?.code || "",
      search: "",
      loading: false,
    }
    this.changeClient = this.changeClient.bind(this)
    this.handleInput = this.handleInput.bind(this)
    this.addEmail = this.addEmail.bind(this)
    this.deleteEmail = this.deleteEmail.bind(this)
    this.applyCoupon = this.applyCoupon.bind(this)
    this.fetchUsersForDropdown = this.fetchUsersForDropdown.bind(this)
    this.debounceFetchUsersForDropdown = debounce(() => this.fetchUsersForDropdown(), 500)
    this.onSearchChange = this.onSearchChange.bind(this)
    this.onEmailInputChange = this.onEmailInputChange.bind(this)
    this.onEmailChange = this.onEmailChange.bind(this)
  }


  componentDidMount() {
    if (this.props.coupon?.id){
      this.setState({ couponSuccess: "Discount Applied!" })
    }
  }

  componentDidUpdate(_, prevState) {
    if (prevState.search !== this.state.search && this.state.search != "" && this.state.search.length > 2) {
      this.debounceFetchUsersForDropdown()
    }
  }

  onSearchChange(newValue) {
      this.setState({ search: newValue })
   }

   onEmailInputChange(newValue, action){
    if (action.action === "input-change"){
      this.setState({new_email: newValue})
    }
   }

  onEmailChange = (selectedOption) => {
    this.addEmail(selectedOption.value)
  };

  fetchUsersForDropdown(){
    if (this.props.currentUser?.roles?.admin && this.state.search != "") {
      this.setState({loading: true})
      fetchUsersNameAndIdOnly(this.state.search).then(users => {
        this.props.changeParentState({ users: users })
        this.setState({ loading: false })
      })
    }
    else if (this.props.currentUser?.roles?.broker) {
      this.setState({ loading: true })
      fetchAllSubBrokers().then(subBrokers => {
        this.props.changeParentState({ users: subBrokers })
        this.setState({ loading: false })
      })
    }
  }

  applyCoupon(promoPrice){
    this.setState({couponError: "", couponSuccess: ""})
    if (promoPrice){
      this.setState({couponError: "Coupon can not be combined with the existing promo"})
      return ""
    }
    applyCoupon(this.state.code, this.props.assigned_user ? this.props.assigned_user : this.props.currentUser?.id).then(coupon => {
      if (coupon?.id){
        this.props.changeParentState({coupon: coupon})
        this.setState({couponSuccess: "Discount Applied!"})
      } else {
        if (this.state.code){
          this.setState({couponError: coupon.error})
        }
      }
    })
  }

  openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  changeClient(e){
    if (e.value === ""){
      this.props.changeParentState({ sub_broker_client: "", client_role: "", sub_broker_client_info: {}, account_credit: null, account_credit_id: null, firm: null, client_timezone: undefined, user_emails: this.props.currentUser.invites })
    } else {
      if (this.props.users[e.value].firm?.broker_charge || this.props.currentUser.roles.broker){
        this.props.changeParentState({ sub_broker_client: e.value, sub_broker_client_info: this.props.users[e.value], client_role: this.props.users[e.value].role, firm: this.props.users[e.value].firm, account_credit: null, account_credit_id: "", client_timezone: this.props.users[e.value].timezone, user_emails: this.props.users[e.value].invites })
      } else {
        this.props.changeParentState({ sub_broker_client: e.value, sub_broker_client_info: this.props.users[e.value], client_role: this.props.users[e.value].role, firm: this.props.users[e.value].firm, account_credit: this.props.users[e.value].account_credit?.amount || null, account_credit_id: this.props.users[e.value].account_credit?.id || "", client_timezone: this.props.users[e.value].timezone, user_emails: this.props.users[e.value].invites })
      }
    }
  }

  handleInput(field) {
    return e => this.setState({ [field]: field === "code" ? e.currentTarget.value.toUpperCase() : e.currentTarget.value });
  }

  addEmail(email){
    this.setState({ emailError: "" }) 
    if (email != "" && this.validateEmail(email) && email.charAt(0) != " " && email.charAt((email.length - 1)) != " "){
      let new_emails = this.props.emails;
      new_emails.push(email)
      this.props.changeParentState({ emails: new_emails})
      this.setState({new_email: ""})
    } else {
      this.setState({emailError: "Email is invalid"}) 
    }
  }

  validateEmail(email) {
    const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return re.test(email);
  }

  deleteEmail(index) {
    let new_emails = this.props.emails
    new_emails.splice(index, 1)
    this.props.changeParentState({ emails: new_emails })
  }

  render() {

    let price = 0;
    let original_price = 0;
    let discount = 0;
    let account_credit = this.props.account_credit;
    let account_credit_component = "";
    let subServices = []
    let bundleSubserviceIds = this.props.selected_bundle?.sub_services?.map(subservice => subservice.id) || [];

    let button = <div className="navStartProjectButton submitOrderButton" onClick={() => this.props.previewOrder(discount, promoPrice, promoName)}>Checkout</div>

    subServices = Object.values(this.props.subservices).filter(sub_service => !bundleSubserviceIds.includes(sub_service.id)).map(subservice => {
      price += parseInt(this.props.location_pricing[subservice.id] ? this.props.location_pricing[subservice.id].price : subservice.price);
      original_price += parseInt(this.props.location_pricing[subservice.id] ? this.props.location_pricing[subservice.id].price : subservice.price);

       return (<div className="orderSummaryLi">
          <div className="serviceLiWords">{this.props.services[subservice.service_id]?.title} - {subservice.title}</div>
          <div style={{ display: "flex" }}>
           <div className="orderSummarySubservicePrice">${parseInt(this.props.location_pricing[subservice.id] ? this.props.location_pricing[subservice.id].price : subservice.price).toFixed(0) }</div>
            {/* {this.props.checkout ? <div className="itemIcon3" onClick={() => this.props.changeParentState({ page: 2, specific_service: subservice.service_id, selected_service: subservice.service_id })}><i className="fas fa-edit settingsServiceIcon m-2"></i></div> : ""} */}
            <div className="itemIcon3" onClick = {() => this.props.deleteSubService(subservice)}><i className="far fa-trash-alt settingsServiceIcon m-2"></i></div>
          </div>
        </div>)
      })

    // let services = Object.values(this.props.services).map(service => {
    //   if (this.props.package_attributes[service.id]){
    //   return (<div className="orderSummaryServicesLi">
    //       {/* <p className="serviceIconProjectIndex" dangerouslySetInnerHTML={{ __html: service.black_icon }} /> */}
    //       <div className="serviceLiWords">{service.title}</div>
    //     </div>
    //     )
    //   } else {
    //     return ""
    //   }
    // })


  if(this.props.selected_bundle?.id) {
    let bundle = this.props.selected_bundle
    let bundle_price = 0
    bundle.sub_services.forEach(sub_service => {
      bundle_price += parseFloat(this.props.location_pricing[sub_service.id] ? this.props.location_pricing[sub_service.id].price : sub_service.price)
    })

    bundle_price = bundle_price * (1 - (bundle.percent_discount / 100))

    price += Math.ceil(bundle_price)

    subServices.push(<div className="orderSummaryLi">
      <div className="serviceLiWords">{bundle.title}</div>
      <div style={{ display: "flex" }}>
        <div className="orderSummarySubservicePrice">${Math.ceil(bundle_price)}</div>
        {/* <div className="itemIcon3" onClick={() => this.props.deleteSubService(subservice)}><i className="far fa-trash-alt settingsServiceIcon m-2"></i></div> */}
      </div>
    </div>)

    Object.values(this.props.subservices).filter(sub_service => bundleSubserviceIds.includes(sub_service.id)).forEach(subservice => original_price += parseInt(this.props.location_pricing[subservice.id] ? this.props.location_pricing[subservice.id].price : subservice.price))
  }

    if (subServices.length === 0){

      button = <button className="navStartProjectButton grayedoutButton">Submit Order</button>

      // subServices = 
      // <div className="noServicesSelected">
      //   <div key="1" className="orderSummaryIcon"><i className="fas fa-cubes"></i></div>
      //   <div>No selected services</div>
      // </div>
    }

    let photographer_appointment_groupings = {}

    let photographersLis = Object.values(this.props.appointments).map(appointment=> {
      if (!photographer_appointment_groupings[`${appointment.contractor.id}-${appointment.appointment_time}`]){
        photographer_appointment_groupings[`${appointment.contractor.id}-${appointment.appointment_time}`] = true
        let appointment_services = `${this.props.services[appointment.service_id].title}`
        Object.values(this.props.appointments).filter(other_appointment => other_appointment.contractor.id === appointment.contractor.id && other_appointment.appointment_time === appointment.appointment_time && appointment.service_id != other_appointment.service_id).forEach(current_appointment => {
          appointment_services += `, ${this.props.services[current_appointment.service_id].title}`
        })
        return <div className="orderSummaryPhotographerLi">
          <UserAvatar user={appointment.contractor} onClick={() => this.openInNewTab(window.location.origin + `/photographers/${appointment.contractor.id}`)} />
          <div className="contractorNameAndAppointment">
            <div>{appointment.contractor.firstName} {appointment.contractor.lastName[0]}</div>
            {/* <div dangerouslySetInnerHTML={{ __html: this.props.services[appointment.service_id].black_icon }}></div> */}
            <div>{appointment_services}</div>
            <div>{formatDatetime(appointment.appointment_time, null, this.props.detected_timezone || this.props.client_timezone)}</div>
            {this.props.checkout ? <div className="changeAppointmentText" onClick={() => this.props.changeParentState({ contractorModalOpen: true, photographer: appointment.contractor, selected_service: appointment.service_id })}>Change Appointment</div> : ""}
          </div>
        </div>
      }
     })

    if (photographersLis.length === 0) {

      button = <button className="navStartProjectButton grayedoutButton">Submit Order</button>

      // photographersLis =
      //   <div className="noServicesSelected">
      //   {/* <div key="2" className="orderSummaryIcon"><i className="fas fa-camera"></i></div> */}
      //     <div>No selected photographers</div>
      //   </div>
    }

    if (Object.values(this.props.appointments).length != Object.values(this.props.package_attributes).length) {
      button = <button className="navStartProjectButton grayedoutButton">Submit Order</button>
    }

    let promoPrice = null
    let promoName = ""
    // let package_attributes_without_reshoot = Object.values(this.props.package_attributes).filter(pack => !this.props.services[pack.service_id].reshoot)
    let package_attributes_without_reshoot = Object.values(Object.keys(this.props.package_attributes).filter((key) => !this.props.services[key].reshoot).reduce((cur, key) => { return Object.assign(cur, { [key]: this.props.package_attributes[key] }) }, {}))

    if (this.props.promos.length > 0 && !this.props.selected_bundle?.id) {
      this.props.promos.forEach(promo => {
        if (this.props.package_attributes[promo.service.id]) {
          if (promo.services.length > 0) {
            promo.services.forEach(service => {
              if (this.props.package_attributes[service.id] && package_attributes_without_reshoot.length === 2) {
                promoPrice = parseInt(promo.price)
                promoName = promo.name
              }
            })
          } else {
            if (package_attributes_without_reshoot.length === 1) {
              promoPrice = parseInt(promo.price)
              promoName = promo.name
            }
          }
        }
      })
    }

    if (promoPrice) {
      let promo_discount = price - promoPrice
      subServices.push(<div className="orderSummaryLi">
        <div>{promoName} Promo Applied: </div>
        <div className="orderSummarySubservicePrice">{promo_discount > 0 ? "-" : ""}${Math.abs(promo_discount).toFixed(0)}</div>
      </div>)

      price = promoPrice
    }

    // if (this.props.homepage && Object.values(this.props.subservices).length > 1){
    //   let discount_combo_amount = 0
    //   if (Object.values(this.props.subservices).length == 2) {
    //     discount_combo_amount = parseInt(price * 5 / 100)
    //   } else if (Object.values(this.props.subservices).length == 3) {
    //     discount_combo_amount = parseInt(price * 10 / 100)
    //   } else {
    //     discount_combo_amount = parseInt(price * 15 / 100)
    //   }
    //   price -= discount_combo_amount

    //   subServices.push(<div className="orderSummaryLi">
    //     <div>Combo Discount</div>
    //     <div className="orderSummarySubservicePrice">-${discount_combo_amount.toFixed(0)}</div>
    //   </div>)
    // }

    if (this.props.coupon?.id){
      let discount_coupon_amount = 0
      if (this.props.coupon.percentage){
        discount_coupon_amount = parseInt(price * this.props.coupon.amount / 100)
        price -= parseInt(price * this.props.coupon.amount / 100)
      } else {
        if (this.props.coupon.amount > price) {
          discount_coupon_amount = price
        } else {
          discount_coupon_amount = this.props.coupon.amount
        }
        price -= discount_coupon_amount
      }

      subServices.push(<div className="orderSummaryLi">
        <div>{this.props.coupon.code}</div>
        <div className="orderSummarySubservicePrice">-${discount_coupon_amount.toFixed(0)}</div>
      </div>)
    }

    if (this.props.account_credit && price > 0){
      if (this.props.account_credit >= price){
        account_credit = this.props.account_credit - price
        price = 0
        original_price = 0
        discount = this.props.account_credit - account_credit
      } else {
        original_price = original_price - this.props.account_credit
        price = price - this.props.account_credit
        discount = this.props.account_credit
        account_credit = 0
      }
    }

    if (discount > 0){
      subServices.push(<div className="orderSummaryLi">
        <div>Account credit applied: </div>
        <div className="orderSummarySubservicePrice">-${discount.toFixed(0) }</div>
      </div>)
    }

    if (account_credit > 0){
      account_credit_component =  <div className="">
          <div className="totalOrderSummaryDiv">Account Credit:</div>
          <div className="totalOrderSummaryPrice">$ {account_credit.toFixed(0)}</div>
        </div>
    }

    let errors = []

    this.props.errors?.forEach((error) => {
      errors.push(<li>{error}</li>)
    })

    if (this.state.emailError){
      errors.push(<li>{this.state.emailError}</li>)
    }

    let assign_to_sub_broker = ""

    const brokersOptions = [{ value: "", label: "Assign to Sub-Broker (optional)" }]
    const usersOptions = [{ value: "", label: "Assign to User (optional)" }]

    if (this.props.currentUser?.roles?.broker){
      Object.values(this.props.users).forEach(subBroker => {
        brokersOptions.push({ value: subBroker.id, label: `${subBroker.firstName} ${subBroker.lastName}` })
      })

      assign_to_sub_broker = <Select className="ml-auto orderSummarySubBrokerSelect" isLoading={this.state.loading} defaultValue={{ value: "", label: "Assign to Sub-Broker (optional)" }} options={brokersOptions} onChange={this.changeClient} />
    }

    if (this.props.currentUser?.roles?.admin) {
          Object.values(this.props.users).forEach(user => {
            usersOptions.push({ value: user?.id, label: `${user?.email} - ${user?.role} - ${user?.account_credit?.amount || 0 }` })
          })
      assign_to_sub_broker = <Select className="ml-auto orderSummarySubBrokerSelect" isLoading={this.state.loading} defaultValue={this.props.sub_broker_client ? { value: this.props.sub_broker_client, label: this.props.sub_broker_client_info?.email} : { value: "", label: "Assign to User (optional)" }} options={usersOptions} onChange={this.changeClient} onInputChange={(newValue) => this.onSearchChange(newValue)}/>
    } 
    // else if (this.props.currentUser?.roles?.admin || this.props.currentUser?.roles?.broker){
    //   if (this.state.loading){
    //     assign_to_sub_broker = <div className="createProjectLogoSpinner">
    //       <img className="assignToUserLogoImageSpinnerGif" src={LogoSpinner}></img>
    //     </div >
    //   } else {
    //     assign_to_sub_broker = <div className="assignToUserButton" onClick={this.fetchUsersForDropdown}>Assign To User</div>
    //   }
    // }

    if (this.props.checkout){
      assign_to_sub_broker = ""
    }

    let emailsLi = this.props.emails.map((email, i) => {
        return <div className="contractorServiceItem">
          <div className="itemTitleEmails" key={i}>{email}</div>
          <div className="" onClick={() => { this.deleteEmail(i) }}><i className="far fa-trash-alt settingsServiceIcon m-2"></i></div>
        </div>
    })

    let add_emails_to_project = ""

    let invitesOptions = this.props.user_emails.filter(email => !this.props.emails.includes(email)).map(email => {return {value: email, label: email }})

    add_emails_to_project = <div className="settingsCompanyInput roleInput">
      <label id="formLabel" style={{ fontSize: "12px", fontWeight: "bold" }}> Add more emails to the calendar events</label>
      <div className="updateUserDropdownAndButton">
        <Select className="ml-auto selectAddEmail" defaultValue={{ value: "", label: "" }} inputValue={this.state.new_email} options={invitesOptions} onChange={this.onEmailChange} onInputChange={(newValue, action) => this.onEmailInputChange(newValue, action)} noOptionsMessage={() => null} styles={{
          placeholder: (base) => {
            return {
              ...base,
              pointerEvents: "none",
              userSelect: "none",
              MozUserSelect: "none",
              WebkitUserSelect: "none",
              msUserSelect: "none"
            };
          },
          input: (base) => ({
            ...base,
            gridTemplateColumns: "0 minmax(min-content, 1fr)"
          })
        }} />
        <button onClick={() => this.addEmail(this.state.new_email)} className="addContractorServiceButton" id="addEmail">Add Email</button>
      </div>
    </div>

    if (this.props.page != 5 && !this.props.homepage){
      // assign_to_sub_broker = "";
      add_emails_to_project = "";
      emailsLi= "";
      button = "";
    }

    if (this.props.modalCheckout){
      button = "";
    }

    let checkout = ""

    if (this.props.checkout){
      checkout = "checkoutStyle"
    }

    let discount_code_component = ""

    if (this.props.checkout){
      discount_code_component = <div className="settingsCompanyInput roleInput">
        <label id="formLabel" style={{ fontSize: "12px", fontWeight: "bold" }}>Coupon Code</label>
        <div className="updateUserDropdownAndButton">
          <input className="formInput" id="" onChange={this.handleInput("code")} type="string" placeholder="Coupon" value={this.state.code} />
          <button onClick={() => this.applyCoupon(promoPrice)} className="addContractorServiceButton" id="addEmail">Apply</button>
        </div>
      </div>
    }
    
    return (
      <div className={this.props.checkout ? "" : "stickySometimes"}>
        <div id={checkout} className="orderSummaryComponent">
          <div className="propertyLocationHeading">Property Location</div>
          <div className="addressPreview"> {this.props.apartment} {this.props.formatted_address} {this.props.checkout ? <div className="itemIcon3" onClick={() => this.props.changeParentState({ page: 1, goingBackToAddress: true })}><i className="fas fa-edit settingsServiceIcon m-2"></i></div> : ""}</div>
          <div className="propertyLocationHeading">Packages</div>
          <div className="subServicesList">
            {subServices}
            {this.props.checkout ? <div className="addMoreServicesText" onClick={() => this.props.changeParentState({
              page: 2,
              errors: [],
              selected_service: -1,
              currentlySelectedService: null })}>{this.props.selected_bundle?.id ? "Change Bundle" : "Add More Services"}</div> : ""}
          </div>
          <div className="propertyLocationHeading">Photographers</div>
          <div className="photographersList">
            {photographersLis}
          </div>
          {this.props.checkout ? <div><div className="propertyLocationHeading">Notes</div>
              <textarea className="orderSummaryNotes" onChange={(e) => this.props.changeParentState({ note: e.currentTarget.value })} placeholder="Provide any neccesary information here..." value={this.props.note} />
            </div> : ""}
          <div className="orderSummaryPriceDiv orderSummarySideDiv">
            <div className="totalOrderSummaryDiv">Total </div>
            {/* {price != original_price && <div className="totalOrderSummaryPrice originalPriceOrderSummary">$ {original_price.toFixed(0)}</div>} */}
            <div className="totalOrderSummaryPrice">$ {price.toFixed(0)}</div>
          </div>
          {account_credit_component}
          {assign_to_sub_broker}
          {discount_code_component}
          <ul className="backendErrorUl" id="orderSummaryCouponError"><li>{this.state.couponError}</li></ul>
          <ul className="successErrorUl" id="orderSummaryCouponError"><li>{this.state.couponSuccess}</li></ul>
          <div className="emailList">
            {emailsLi}
          </div>
          {add_emails_to_project}
          <ul className="backendErrorUl">{errors}</ul>
        </div>
        {button ? <div className="buttonDiv">
          {button}
        </div> : ""}
    </div>
    )
  }
}

export default withRouter(OrderSummaryComponent);