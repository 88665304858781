import {
  AboutUsPageContainer,
  CareersContainer,
  ContactsContainer,
  FAQContainer,
  FloorPlansPageContainer,
  InteractiveToursPageContainer,
  PhotographyPageContainer,
  PrivacyPolicyContainer,
  RenderingPageContainer,
  TermsContainer,
  VideoPageContainer,
  VirtualStagingPageContainer,
  BlogShow,
  BlogIndex,
  LocationsContainer,
  DronePageContainer,
  ScheduleConsultationWithDiscountPage,
  ScheduleConsultationPage,
  ScheduleConsultationWithCallbackPage
} from '../components/pages';
import HomepageContainer from '../components/home_page/homepage_container';
import LocationContainer from "../components/pages/location/LocationContainer";

const ROUTES = {
  HOME: '/',
  FLOOR_PLANS: '/floor-plans',
  INTERACTIVE_TOURS: '/interactive-tours',
  PHOTOGRAPHY: '/photography',
  DRONE: '/drone',
  RENDERING: '/rendering',
  VIDEO: '/video',
  VIRTUAL_STAGING: '/virtual-staging',
  ABOUT: '/about',
  CONTACTS: '/contact',
  LOGIN: '/login',
  SIGNUP: '/signup',
  CREATE_PROJECT: '/create-project',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_OF_SERVICE: '/terms-of-service',
  FAQ: '/questions',
  DASHBOARD: '/dashboard',
  CAREERS: '/careers',
  CAREERS_APPLY: '/careers/apply',
  BLOG_SHOW: '/blog/:id',
  BLOG_INDEX: '/blog',
  LOCATIONS: '/coverage',
  LOCATION_SHOW: '/coverage/:city',
  HOME_WITH_REFERRAL: '/referral/:referral_code',
  APPLY_FOR_DISCOUNT: '/apply-for-discount',
  SCHEDULE_CONSULTATION: '/schedule-consultation',
  SCHEDULE_CALLBACK: '/schedule-callback'
};

const PUBLIC_PAGES = [
  {
    index: 1,
    exact: true,
    path: ROUTES.HOME,
    page: HomepageContainer,
    show_two_modals: true
  },
  {
    index: 2,
    exact: true,
    path: ROUTES.FLOOR_PLANS,
    page: FloorPlansPageContainer,
    show_two_modals: true
  },
  {
    index: 3,
    exact: true,
    path: ROUTES.INTERACTIVE_TOURS,
    page: InteractiveToursPageContainer,
    show_two_modals: true
  },
  {
    index: 4,
    exact: true,
    path: ROUTES.PHOTOGRAPHY,
    page: PhotographyPageContainer,
    show_two_modals: true
  },
  {
    index: 5,
    exact: true,
    path: ROUTES.RENDERING,
    page: RenderingPageContainer,
    show_two_modals: true
  },
  {
    index: 6,
    exact: true,
    path: ROUTES.VIDEO,
    page: VideoPageContainer,
    show_two_modals: true
  },
  {
    index: 7,
    exact: true,
    path: ROUTES.VIRTUAL_STAGING,
    page: VirtualStagingPageContainer,
    show_two_modals: true
  },
  {
    index: 8,
    exact: true,
    path: ROUTES.ABOUT,
    page: AboutUsPageContainer,
    show_two_modals: true
  },
  {
    index: 9,
    exact: true,
    path: ROUTES.CONTACTS,
    page: ContactsContainer,
    show_two_modals: true
  },
  {
    index: 10,
    exact: true,
    path: ROUTES.PRIVACY_POLICY,
    page: PrivacyPolicyContainer,
    show_two_modals: true
  },
  {
    index: 11,
    exact: true,
    path: ROUTES.TERMS_OF_SERVICE,
    page: TermsContainer,
    show_two_modals: true
  },
  {
    index: 12,
    exact: true,
    path: ROUTES.FAQ,
    page: FAQContainer,
    show_two_modals: true
  },
  {
    index: 13,
    exact: true,
    path: ROUTES.CAREERS,
    page: CareersContainer,
    show_two_modals: true
  },
  {
    index: 14,
    exact: true,
    path: ROUTES.BLOG_SHOW,
    page: BlogShow,
    show_two_modals: true
  }, 
  {
    index: 15,
    exact: true,
    path: ROUTES.BLOG_INDEX,
    page: BlogIndex,
    show_two_modals: true
  },
  {
    index: 16,
    exact: true,
    path: ROUTES.LOCATIONS,
    page: LocationsContainer,
    show_two_modals: true
  },
  {
    index: 17,
    exact: true,
    path: ROUTES.LOCATION_SHOW,
    page: LocationContainer,
    navbarDisableWaveBackground: true,
    show_two_modals: true
  },
  {
    index: 18,
    exact: true,
    path: ROUTES.HOME_WITH_REFERRAL,
    page: HomepageContainer,
    show_two_modals: true
  },
  {
    index: 19,
    exact: true,
    path: ROUTES.DRONE,
    page: DronePageContainer,
    show_two_modals: true
  },
  {
    index: 20,
    exact: true,
    path: ROUTES.APPLY_FOR_DISCOUNT,
    page: ScheduleConsultationWithDiscountPage,
    navbarDisableWaveBackground: true,
    dontShowAd: true,
    navbarHideMiddleButtons: true,
    show_two_modals: true
  },
  {
    index: 21,
    exact: true,
    path: ROUTES.SCHEDULE_CONSULTATION,
    page: ScheduleConsultationPage,
    navbarDisableWaveBackground: true,
    dontShowAd: true,
    navbarHideMiddleButtons: true,
    show_two_modals: true
  }, 
  {
    index: 22,
    exact: true,
    path: ROUTES.SCHEDULE_CALLBACK,
    page: ScheduleConsultationWithCallbackPage,
    navbarDisableWaveBackground: true,
    dontShowAd: true,
    navbarHideMiddleButtons: true,
    show_two_modals: true
  },
];

export {ROUTES, PUBLIC_PAGES};
