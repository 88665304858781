import React, {useEffect, useState} from "react";
import {fetchAds} from "../../../util/ad_api_utils";

import {matchPath, useHistory, useLocation} from "react-router-dom";
import {showToast} from "../../../actions/toast_actions";
import {connect} from "react-redux";
import {AdModalForm} from "./AdModalForm";

const AdModal = ({pagesToShowOn, showToast, currentUser}) => {
  const [ads, setAds] = useState([])
  const [selectedAd, setSelectedAd] = useState(null)
  const history = useHistory()
  const location = useLocation()
  const [shouldShowOnCurrentPage, setShouldShowOnCurrentPage] = useState(false)
  const [show, setShow] = useState(false)
  // const show_two_modals = matchPath(location.pathname, { path: "/book-shoot", exact: true }) || matchPath(location.pathname, { path: "/book", exact: true })
  const show_two_modals = true
  const [currentAdType, setCurrentAdType] = useState("popup");

  useEffect(() => {
    calculateWhetherToShow(location)

    history.listen((location) => {
      calculateWhetherToShow(location)
    });

    fetchAds().then(setAds)
  }, [])

  useEffect(() => {
    if (!shouldShowOnCurrentPage) {
      setSelectedAd(null)
      setShow(false)
    }
  }, [shouldShowOnCurrentPage])

  useEffect(() => {
    if (!shouldShowOnCurrentPage) return
    if (ads.length === 0) return

    let showable_ads = ads.filter(ad => !ad.book_shoot_page);

    if (matchPath(location.pathname, { path: "/book-shoot", exact: true })) {
      showable_ads = ads.filter(ad => ad.book_shoot_page)
    }
    
    if (currentAdType) {
      showable_ads = showable_ads.filter(ad => ad.ad_type === currentAdType);
    }

    if (showable_ads.length === 0) {
      setSelectedAd(null)
    }

    const ad = showable_ads[Math.floor(Math.random() * showable_ads.length)];
    setSelectedAd(ad)

    const interval = setTimeout(() => {
      setShow(true)
    }, 4000)

    return () => clearInterval(interval)
  }, [ads, shouldShowOnCurrentPage, currentAdType])

  const calculateWhetherToShow = (location) => {
    setShouldShowOnCurrentPage(pagesToShowOn.some(page => {
      if (currentUser || page.dontShowAd) {
        return false
      }
      return matchPath(location.pathname, {path: page.path, exact: page.exact})
    }))
  }

  const onClose = (e) => {
    e.preventDefault()
    setShow(false)
    if (show_two_modals && currentAdType === "popup") {
      setCurrentAdType("slide_out");
      setTimeout(() => {
        setShow(true);
      }, 2000); // Adjust the delay as needed
    }
  }

  const onSubscribed = () => {
    setShow(false)
  }

  if (!selectedAd) return <></>

  const type = selectedAd.description ? 'vertical' : 'horizontal'
  const isVertical = type === 'vertical'
  const imageUrl = selectedAd.image?.url

  return (<AdModalForm showToast={showToast} title={selectedAd.title} description={selectedAd.description}
                 button_text={selectedAd.button_text}
                 imageUrl={imageUrl}
                 isVertical={isVertical} 
                 adId={selectedAd.id}
                 ad_type={selectedAd.ad_type}
                 onClose={onClose}
                 show={show}
                 type={type}
                 onSubscribed={onSubscribed}
    />)
}

const msp = (state) => ({
  currentUser: state.session.currentUser ? state.entities.users[state.session.currentUser.id] : null
});

const mdp = (dispatch) => ({
  showToast: (message) => dispatch(showToast(message))
});

export default connect(msp, mdp)(AdModal);