import React from "react"
import ContractorTimeslotItem from "./contractor_timeslot_item"
import {stars} from "../../util/rating_util"
import {withRouter} from "react-router-dom"
import UserAvatar from "../photographers/photograher_avatar"
import LogoSpinner from "../../../../assets/images/landing/video-thumbnails/MA-logo-spinner-transparent.gif";
import {formatDatetime} from "../../util/date_util";
import debounce from 'debounce'
import { debouncePromise } from "../../util/custom_function_utils"
import PhotographerReviews from "../photographers/photographers_reviews"
import {fetchPhotographerWithReviews} from "../../util/service_api_util";
import Modal from "react-modal";

class ContractorCalendar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selected: new Date(),
      selectedTime: "",
      dates: [],
      month: new Date(),
      currentDay: new Date(),
      confirmModal: false,
      isLargeScreen: true,
      loading: false,
      reviewsModalOpen: false,
      detailedPhotographer: null,
      reviewsLoading: false,
    }
    this.handler = this.handler.bind(this)
    this.applyTime = this.applyTime.bind(this)
    this.addMonth = this.addMonth.bind(this)
    this.subtractMonth = this.subtractMonth.bind(this)
    this.addDays = this.addDays.bind(this)
    this.subtractDays = this.subtractDays.bind(this)
    this.handleTimeSelect = this.handleTimeSelect.bind(this)
    this.toggleReviewsModal = this.toggleReviewsModal.bind(this);
    this.fetchDetailedPhotographer = this.fetchDetailedPhotographer.bind(this);
    this.updatePredicate = this.updatePredicate.bind(this)
    this.debouceScrollTo = debounce((selector) => this.scrollTo(selector), 200)
    this.debounceFetchTimes = debouncePromise((options) => {
      return this.props.fetchTimes(options);
    }, 500);
  }

  componentDidMount() {
    document.body.style.overflow = 'hidden';
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);

    let today_date = new Date()
    today_date.setDate(today_date.getDate() + 1)

    let previous_time_set = false 

    if (this.props.appointments[this.props.selectedService]?.contractor && this.props.appointments[this.props.selectedService]?.appointment_time) {
      today_date = this.props.appointments[this.props.selectedService]?.appointment_time
      previous_time_set = true
    }

    let dates = this.getDaysInMonth(today_date)
    this.setState({
      dates: dates,
      currentDay: today_date,
      selected: today_date,
      selectedTime: previous_time_set ? today_date : "",
      month: today_date,
      loading: true
    })
    
    this.debounceFetchTimes({
      date: today_date,
      contractor_id: this.props.photographer.id,
      service_id: this.props.selectedService,
      street: this.props.street,
      timezone: this.props.detected_timezone || this.props.project?.client?.timezone || this.props.client_timezone
    }).then(() => this.setState({ loading: false }))
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
    document.body.style.overflow = 'unset';
  }

  toggleReviewsModal() {
    if (!this.state.reviewsModalOpen) {
      // Open modal and fetch detailed photographer
      this.fetchDetailedPhotographer();
    } else {
      // Close modal
      this.setState({ reviewsModalOpen: false });
    }
  }

  async fetchDetailedPhotographer() {
    this.setState({ reviewsLoading: true, reviewsModalOpen: true });
    try {
      const detailedPhotographer = await fetchPhotographerWithReviews(this.props.photographer.id);
      this.setState({ detailedPhotographer, reviewsLoading: false });
    } catch (error) {
      console.error("Failed to fetch detailed photographer:", error);
      this.setState({ reviewsLoading: false });
    }
  }

  scrollTo(selector) {
    const element = document.querySelector(selector)
    if (element) element.scrollIntoView()
  }

  updatePredicate() {
    this.setState({isLargeScreen: window.innerWidth > 550});
  }

  addDays() {
    let currentDay = new Date(this.state.currentDay)

    if (this.state.isLargeScreen){
      currentDay.setDate(currentDay.getDate() + 10)
    } else {
      currentDay.setDate(currentDay.getDate() + 5)
    }

    if (currentDay.getMonth() !== this.state.currentDay.getMonth()) {
      currentDay.setDate(1)
    }

    let dates = this.getDaysInMonth(currentDay)
    this.setState({ dates: dates, currentDay: currentDay, month: currentDay, selected: dates[0], selectedTime: "", loading: true})

    this.debounceFetchTimes({
      date: dates[0],
      contractor_id: this.props.photographer.id,
      service_id: this.props.selectedService,
      street: this.props.street,
      timezone: this.props.detected_timezone || this.props.project?.client?.timezone || this.props.client_timezone
    }).then(() => this.setState({ loading: false }))
  }

  subtractDays() {
    let currentDay = new Date(this.state.currentDay)

    if (this.state.isLargeScreen) {
      currentDay.setDate(currentDay.getDate() - 10)
    } else {
      currentDay.setDate(currentDay.getDate() - 5)
    }

    let todayDate = new Date()

    todayDate.setDate(todayDate.getDate() + 1)

    if (currentDay < todayDate && !this.props.currentUser?.roles?.admin) {
      let dates = this.getDaysInMonth(todayDate)
      this.setState({ dates: dates, currentDay: todayDate, month: todayDate, selected: dates[0], selectedTime: "", loading: true })
      this.debounceFetchTimes({
        date: dates[0],
        contractor_id: this.props.photographer.id,
        service_id: this.props.selectedService,
        street: this.props.street,
        timezone: this.props.detected_timezone || this.props.client_timezone || this.props.project?.client?.timezone || this.props.currentUser?.timezone
      }).then(() => this.setState({ loading: false }))
    } else {
      let dates = this.getDaysInMonth(currentDay)
      this.setState({ dates: dates, currentDay: currentDay, month: currentDay, selected: dates[0], selectedTime: "", loading: true })
      this.debounceFetchTimes({
        date: dates[0],
        contractor_id: this.props.photographer.id,
        service_id: this.props.selectedService,
        street: this.props.street,
        timezone: this.props.detected_timezone || this.props.client_timezone || this.props.project?.client?.timezone || this.props.currentUser?.timezone
      }).then(() => this.setState({ loading: false }))
    }
  }

  applyTime() {
    if (this.state.selectedTime !== "") {
      if (this.props.updating && !this.state.confirmModal){
        this.setState({confirmModal: true})
        this.debouceScrollTo(".sub-modal-background")
      } else{
      this.props.setAppointmentTime(this.state.selectedTime, this.props.photographer)
      this.props.changeSelected(null, null, true)
      this.props.handleNext && this.props.handleNext()
      }
    }
  }

  addMonth() {
    let date = new Date(this.state.month.getTime())
    date.setMonth(this.state.month.getMonth() + 1)
    date.setDate(1)

    let dates = this.getDaysInMonth(date)

    this.setState({dates: dates, month: date, currentDay: date})
  }

  subtractMonth() {
    let today_date = new Date()
    let date = new Date(this.state.month.getTime())

    if (date.getMonth() != today_date.getMonth()) {
      date.setMonth(this.state.month.getMonth() - 1)
    }

    if (date.getMonth() === today_date.getMonth()) {
      date.setDate(today_date.getDate() + 1)
    } else {
      date.setDate(1)
    }

    let dates = this.getDaysInMonth(date)

    this.setState({dates: dates, month: date, currentDay: date})
  }

  getDaysInMonth(date) {
    let month = date.getMonth()
    let new_date = new Date(date.getTime())

    let days = []
    while (new_date.getMonth() === month) {
      days.push(new Date(new_date))
      new_date.setDate(new_date.getDate() + 1)
    }
    return days
  }

  changeSelected(index) {
    this.setState({ selected: this.state.dates[index], selectedTime: "", loading: true })
    this.debounceFetchTimes({
      date: this.state.dates[index],
      contractor_id: this.props.photographer.id,
      service_id: this.props.selectedService,
      street: this.props.street,
      timezone: this.props.detected_timezone || this.props.client_timezone || this.props.project?.client?.timezone || this.props.currentUser?.timezone
    }).then(() => this.setState({loading: false}))
  }

  handler(newState) {
    this.setState(newState)
  }

  handleTimeSelect(time) {
    if (this.state.selectedTime.toString() === time.toString()) {
      this.setState({selectedTime: ""})
    } else {
      this.setState({selectedTime: time})
    }
  }


  render() {

    const { reviewsLoading, detailedPhotographer, reviewsModalOpen } = this.state;

    let dates = this.state.dates.slice(0, this.state.isLargeScreen ? 10 : 5).map((date, index) =>
      <div className="createProjectDateOptions" key={index}
           id={this.state.selected?.toString() === date.toString() ? "createProjectDateOptionSelected" : ""}
           onClick={() => this.changeSelected(index)}>
        <div className="createProjectDate"
             id={this.state.selected?.toString() === date.toString() ? "createProjectDateSelected" : ""}>{date.getDate()}</div>
        <div className="createProjectDateDay"
             id={this.state.selected?.toString() === date.toString() ? "createProjectDateDaySelected" : ""}>{date.toLocaleString('default', {weekday: 'short'})}</div>
      </div>
    )

    let selectedTime = this.state.selectedTime


    let time_slots = this.props.times.map(time => {
      return {time: new Date(time.start_time), unavailable: time.unavailable}
    })

    let times = time_slots.map((time_slot, i) =>
      <ContractorTimeslotItem key={i} time={time_slot.time} unavailable={time_slot.unavailable} selected={selectedTime.toString() === time_slot.time.toString()} project={this.props.project}
                              currentUser={this.props.currentUser} detected_timezone={this.props.detected_timezone} client_timezone={this.props.client_timezone} handleTimeSelect={this.handleTimeSelect} changeParentState={this.handler}/>
    )

    let photographer = this.props.photographer

    let star_rating = stars(photographer.average_rating)

    let modal = ""

    if (this.state.confirmModal){
      modal = <div className="modal-background sub-modal-background" id={this.props.reschedulingContractorCancel ? "sub-modal-background-reschedule" : ""} onClick={() => this.setState({confirmModal: false})}> 
        <div className="orderpreviewModal" onClick={e => e.stopPropagation()}>
          <div className="orderPreviewModalComponent">
            <div className="contractorModalHeading">
              <div className="confirmationModalDescription">Are you sure you want to make this change?</div>
            </div>
            <div className="confirmationTimeSlotsButtons">
              <div onClick={() => this.setState({ confirmModal: false })} className="signupFormPrevious">Cancel</div>
              <div onClick={this.applyTime} className="signupFormNext" id={this.state.selectedTime === "" ? 'disabled' : ''}>Apply</div>
            </div>
          </div>
        </div>
      </div>
    }

    let modalClass = "orderpreviewModal contractorSchedulingModal"

    if (this.props.homepageModal){
      modalClass = "orderpreviewModal addServiceModalContent"
    }

    if (this.state.loading){
      times = [<div className="MapLogoSpinner">
        <img className="contractorCalendarLogoImageSpinnerGif" src={LogoSpinner}></img>
      </div>]
    }

    return <div className="modal-background" id={this.props.reschedulingContractorCancel ? "updatingContractorCancelModal" : ""} onClick={() => this.props.changeParentState({modalOpen: false})}>
      <div className={modalClass} onClick={e => e.stopPropagation()}>
        <div className="orderPreviewModalComponent">
          {modal}
          <Modal
            isOpen={this.state.reviewsModalOpen}
            onRequestClose={this.toggleReviewsModal}
            ariaHideApp={false}
            className="photographerReviewModal"
            overlayClassName="reviewModalOverlay"
          >
            {reviewsLoading ? (
              <div onClick={(e) => e.stopPropagation()}>
                <div className="createProjectLogoSpinner">
                  <img className="logoImageSpinnerGif" src={LogoSpinner}></img>
                </div>
              </div>
            ) : detailedPhotographer ? (
              <div onClick={(e) => e.stopPropagation()}>
                  <PhotographerReviews reviewModal={true} changeSelected={this.toggleReviewsModal} toggleReviewsModal={this.toggleReviewsModal} currentUser={this.props.currentUser} photographer={detailedPhotographer} />
              </div>
            ) : (
              <p>No reviews available.</p>
            )}
          </Modal>
          {this.state.isLargeScreen ? 
          <div className="contractorModalHeading">
            <div className="contractorModalDescription">Book a Time Slot with {photographer.firstName}</div>
            {/* <div className="contractorModalButton" onClick={() => this.props.changeSelected()}>Cancel</div> */}
          </div> : "" }
          <div className="photographerSubheading photographerSubheadingTop">Below you will find the available dates and
            times for the selected photographer.
          </div>
          <div className="mainSchedulingComponentGroup">
            <div className="dateSelectionGrouping">
              <div className="timeslotCard">
                <div className="photographerCardHeader" id="contractorCardHeader">
                  <div className="d-flex justify-content-between">
                    <UserAvatar user={photographer}/>
                    <div className="photographerCardInfo">
                      <div className="photographerCardName">{photographer.firstName || photographer.first_name} {photographer?.lastName ? photographer?.lastName[0] : false || photographer?.last_name ? photographer?.last_name[0] : false}</div>
                      {photographer.ratings_amount > 0 && <div className="photographerCardRatingNew">
                        {/* <div>{star_rating}</div> */}
                        <div
                          className="photographerCardReviewNew"
                          onClick={this.toggleReviewsModal}
                        >
                        {photographer.ratings_amount} Reviews
                        </div>
                      </div>}
                      {this.state.isLargeScreen ? <div>
                        <a className="photographerCardButton" href={`/photographers/${photographer.id}`}
                           target="_blank">
                          <i className="far fa-user-circle"/>
                          Profile
                        </a>
                      </div> : "" }
                    </div>
                    {photographer.ratings_amount > 0 && <div className="photographerCardRatingOld timeslotCardRating">
                      {/* <div>{star_rating}</div> */}
                      <div
                        className="photographerCardReview"
                        onClick={this.toggleReviewsModal}
                      >
                        {photographer.ratings_amount} Reviews
                      </div>
                    </div>}
                  </div>
                </div>
              </div>
              <div className="contractorTimeSlotDateHeadingGroup">
                <div className="contractorTimeSlotsHeading">
                  <div className="selectedWeekday">
                    {this.state.selected ? formatDatetime(this.state.selected, "dddd", this.props.detected_timezone || this.props.client_timezone || this.props.project?.client?.timezone || this.props.currentUser?.timezone ) : ''},
                  </div>
                  <div
                    className="selectedMonth">{this.state.selected ? formatDatetime(this.state.selected, "MMMM", this.props.detected_timezone || this.props.client_timezone || this.props.project?.client?.timezone || this.props.currentUser?.timezone) : ''} </div>
                  <div className="selectedDate">{this.state.selected ? formatDatetime(this.state.selected, "DD", this.props.detected_timezone || this.props.client_timezone || this.props.project?.client?.timezone || this.props.currentUser?.timezone) + ', ' : ''}</div>
                  <div className="selectedDate">{this.state.selected ? formatDatetime(this.state.selected, "YYYY", this.props.detected_timezone || this.props.client_timezone || this.props.project?.client?.timezone || this.props.currentUser?.timezone) : ''}</div>
                </div>
                <div className="selectedArrows">
                  <div onClick={this.subtractDays} className="selectedArrowLeft"><i className="fas fa-chevron-left"></i>
                  </div>
                  <div className="selectedArrowMonth">{this.state.month.toLocaleString('default', {month: 'long'})}</div>
                  <div onClick={this.addDays} className="selectedArrowRight"><i className="fas fa-chevron-right"></i>
                  </div>
                </div>
              </div>
              <div className="daysGroupDiv">
                <div className="contractorTimeSlotsDates">
                  {dates}
                </div>
              </div>
            </div>
            <div className="timeSlotGrouping">
              <div className="selectTime">Select time</div>
              <div className="small mb-3">
                Times are shown in {this.props.detected_timezone || this.props.client_timezone || this.props.project?.client?.timezone || this.props.currentUser?.timezone}
              </div>
              <div className="timesDiv">
                {times.length > 0 ? times : <div className="noTimeslotsDiv">No timeslots for this date</div>}
              </div>
            </div>
          </div>
          <div className="contractorTimeSlotsButtons">
            <div onClick={() => this.props.changeSelected(null)} className="signupFormPrevious">Cancel</div>
            <div onClick={this.applyTime} className="signupFormNext" id={this.state.selectedTime === "" ? 'disabled' : ''}>Apply</div>
          </div>
        </div>
        <div className="modalMobilePushup"></div>
      </div>
    </div>
  }
}

export default withRouter(ContractorCalendar)